import React, { useState } from 'react'
import CompanyProfile from './components/CompanyProfile'
import ColorSetting from './components/ColorSetting'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'

const CompanySetting = () => {
    const [index, setIndex] = useState(0)
  return (
    <div>
        <div className='bg-[#EBEFF5] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={false} NameSubNavbar={'Structure'} LinkBack={''} NameNavbar={'Company Setting'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border '>
                        <div className='flex gap-6 items-center border-b-4 pb-3'>
                            <button onClick={() => setIndex(0)} className={index === 0 ?'text-[#780000] bg-[#FFE9E9] p-[10px] text-[11px] font-medium rounded-xl' : 'text-[#A8A8A8] p-[10px] text-[11px] font-medium rounded-xl'}>Company Profile</button>
                            <button onClick={() => setIndex(1)} className={index === 1 ?'text-[#780000] bg-[#FFE9E9] p-[10px] text-[11px] font-medium rounded-xl' : 'text-[#A8A8A8] p-[10px] text-[11px] font-medium rounded-xl'}>Color Setting</button>
                        </div>

                        <div className=''>

                            {index === 0 ? (
                                <CompanyProfile />
                            ) : index === 1? (
                                <ColorSetting />
                            ) :
                            ""}
                            
                            <div className='relative pb-[36px] pt-[11px]'>
                                <div className='flex gap-[12px] absolute right-0'>
                                    <button className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                    <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompanySetting