import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import {AiOutlinePlus} from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import Modal from '../../components/Modal'
import { IoMdArrowDropdown } from 'react-icons/io'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'

const User = () => {
    const [showAddUser, setShowAddUser] = useState()
    const [showEditUser, setShowEditUser] = useState()
  return (
    <div>
        <Modal 
            activeModal={showAddUser}
            title={'Add User'}
            buttonClose={ () => setShowAddUser(!showAddUser)}
            width={'551px'}
            content= {
                <div className='space-y-[26px] w-[full]'>
                    <div className="flex gap-[19px]">
                        <div className='text-[#737373]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Username <span className='text-[#780000]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[12px] border rounded-md text-[12px]' placeholder='Your Username...'/>
                        </div>
                        <div className='text-[#737373]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Employee Name <span className='text-[#780000]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[12px] border rounded-md text-[12px]' placeholder='Employee name...'/>
                        </div>
                    </div>

                    <div className='w-[227px]'>
                        <h1 className='text-[11px] font-medium mb-[5px]'>Status <span className='text-[#780000]'>*</span></h1>
                        <div className='relative'>
                            <select value={''} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[8px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Status</option>
                                <option value={''}>Human Resource</option>
                                <option value={''}>Finance</option>
                                <option value={''}>Employee</option>
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                        </div>
                    </div>

                    <div className="flex gap-[19px] ">
                        <div className='text-[#737373]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Password <span className='text-[#780000]'>*</span></h1>
                            <input type="password" className='py-[10px] px-[12px] border rounded-md text-[12px]' placeholder='Your password...'/>
                        </div>
                        <div className='text-[#737373]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Confirm Password <span className='text-[#780000]'>*</span></h1>
                            <input type="password" className='py-[10px] px-[12px] border rounded-md text-[12px]' placeholder='Confirm password...'/>
                        </div>
                    </div>

                    <div className='relative pb-[36px] pt-[11px]'>
                        <div className='flex gap-[12px] absolute right-0'>
                            <button className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                            <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Add</button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditUser}
            title={'Edit User'}
            buttonClose={ () => setShowEditUser(!showEditUser)}
            width={'551px'}
            content= {
                <div className='space-y-[26px] w-[full]'>
                    <div className="flex gap-[19px]">
                        <div className='text-[#737373]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Username <span className='text-[#780000]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[12px] border rounded-md text-[12px]' placeholder='Your Username...'/>
                        </div>
                        <div className='text-[#737373]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Employee Name <span className='text-[#780000]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[12px] border rounded-md text-[12px]' placeholder='Employee name...'/>
                        </div>
                    </div>

                    <div className='w-[227px]'>
                        <h1 className='text-[11px] font-medium mb-[5px]'>Status <span className='text-[#780000]'>*</span></h1>
                        <div className='relative'>
                            <select value={''} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[8px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Status</option>
                                <option value={''}>Human Resource</option>
                                <option value={''}>Finance</option>
                                <option value={''}>Employee</option>
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[11px] text-[#737373] mb-[12px]'>Change Password?</h1>
                        <div class="flex items-center mb-4">
                            <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                            <label for="default-checkbox" class="ml-[12px] text-sm font-medium text-[#737373] dark:text-gray-300">Yes</label>
                        </div>
                    </div>

                    <div className="flex gap-[19px] ">
                        <div className='text-[#737373]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Password <span className='text-[#780000]'>*</span></h1>
                            <input type="password" className='py-[10px] px-[12px] border rounded-md text-[12px]' placeholder='Your password...'/>
                        </div>
                        <div className='text-[#737373]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Confirm Password <span className='text-[#780000]'>*</span></h1>
                            <input type="password" className='py-[10px] px-[12px] border rounded-md text-[12px]' placeholder='Confirm password...'/>
                        </div>
                    </div>

                    <div className='relative pb-[36px] pt-[11px]'>
                        <div className='flex gap-[12px] absolute right-0'>
                            <button className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                            <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Submit</button>
                        </div>
                    </div>
                </div>
            }
        />
        <div className='bg-[#EBEFF5] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={false} NameSubNavbar={''} LinkBack={''} NameNavbar={'User'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className="flex items-center justify-between">
                            <div className='text-[#0A0A1F]'>
                                <h1 className='text-[16px] font-semibold mb-[5px]'>User</h1>
                                <h1 className='text-[10px]'>20 Post</h1>
                            </div>
                            <div>
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                    <div className='relative'>
                                        <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                        <input placeholder='Search Brand...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                    </div>
                                    <button onClick={() => setShowAddUser(!showAddUser)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus  className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add User</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="relative overflow-x-auto">
                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead class="text-[10px] text-[#737373] uppercase border-b dark:bg-gray-700 dark:text-gray-400 font-semibold">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            USERNAME
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            EMPLOYEE NAME
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            STATUS
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            ACTION
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-[#454545] text-[12px]'>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <th scope="row" class="px-6 py-[29px] font-medium  whitespace-nowrap dark:text-white">
                                            Admin
                                        </th>
                                        <td class="px-6 py-[29px]">
                                            Muh Rizieq Fazlulrahman Djafar
                                        </td>
                                        <td class="px-6 py-[29px]">
                                            Enable
                                        </td>
                                        <td class="px-6 py-[29px]">
                                            <div className='w-full flex items-center gap-[12px]'>
                                                <button onClick={() => setShowEditUser(!showEditUser)} className='w-[29px] h-[29px] bg-[#24A4E0] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[white] text-lg'/>
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#FF5F5F] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[white] text-lg'/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <th scope="row" class="px-6 py-[29px] font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Admin
                                        </th>
                                        <td class="px-6 py-[29px]">
                                            Muh Rizieq Fazlulrahman Djafar
                                        </td>
                                        <td class="px-6 py-[29px]">
                                            Enable
                                        </td>
                                        <td class="px-6 py-[29px]">
                                            <div className='w-full flex items-center gap-[12px]'>
                                                <button className='w-[29px] h-[29px] bg-[#24A4E0] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[white] text-lg'/>
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#FF5F5F] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[white] text-lg'/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <th scope="row" class="px-6 py-[29px] font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Admin
                                        </th>
                                        <td class="px-6 py-[29px]">
                                            Muh Rizieq Fazlulrahman Djafar
                                        </td>
                                        <td class="px-6 py-[29px]">
                                            Enable
                                        </td>
                                        <td class="px-6 py-[29px]">
                                            <div className='w-full flex items-center gap-[12px]'>
                                                <button className='w-[29px] h-[29px] bg-[#24A4E0] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[white] text-lg'/>
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#FF5F5F] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[white] text-lg'/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default User