import React, { useEffect, useState } from 'react'
import NavbarLandingPage from '../../components/NavbarLandingPage'
import { RiArrowRightSFill, RiMapPinLine } from 'react-icons/ri'
import { HiOutlineOfficeBuilding, HiOutlineMapPin } from 'react-icons/hi'
import { BsBookmark } from 'react-icons/bs'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { PiSuitcaseSimpleBold } from "react-icons/pi";
import { Ellipse1, Ellipse2, Ellipse3, Ellipse4, LogoStartup, DashboardImage, IconDashboard, IconDashboard1, IconDashboard2, IconDashboard3, IconDashboard4, IconDashboard5} from '../../assets'
import FooterLandingPage from '../../components/FooterLandingPage'
import Api from '../../Api'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const DashboardLandingPage = () => {

    const navigate = useNavigate()
    const [dataRecruitment, setDataRecruitment] = useState('')
    const limitedData = dataRecruitment.slice(0, 4);
    const GetRecruitment = async() => {
        try {
            const response = await Api.GetRecruitment()
            setDataRecruitment(response.data.response)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const dataColor = [
        { background: 'bg-[#9FCBE78A]', border: 'border-[#0095E2]', text: 'text-[#0095E2]' },
        { background: 'bg-[#E79F9F8A]', border: 'border-[#780000]', text: 'text-[#780000]' },
        { background: 'bg-[#B19FE78A]', border: 'border-[#9747FF]', text: 'text-[#9747FF]' },
        { background: 'bg-[#FEEACB8A]', border: 'border-[#DC891A]', text: 'text-[#DC891A]' }
    ];

    function dayPublished(published_date) {
        const today = moment(); // Tanggal hari ini
        const publishDate = moment(published_date); // Tanggal publish
      
        const diffInDays = today.diff(publishDate, 'days');
      
        console.log(today.format('DD MM YYYY'), 'hari ini');
        console.log(publishDate.format('DD MM YYYY'), 'hari publish');
        console.log('hasil =', diffInDays);
      
        return diffInDays;
      }

    useEffect(() => {
        GetRecruitment()
    }, [])

    return (
        <div>
            <NavbarLandingPage/>
            <div className='pt-[128px] pb-[107px] relative'>
                {/* Section 1 */}
                <div className='flex text-[48px] text-[#282828] items-center justify-center font-bold mb-[27px] text-center'>
                    <img alt='icon' src={Ellipse1} className='flex items-center justify-center absolute top-0 -z-10' />
                    <img alt='icon' src={Ellipse2} className='flex items-center justify-center absolute top-0 -z-10' />
                    <img alt='icon' src={Ellipse3} className='flex items-center justify-center absolute top-0 -z-10' />
                    <img alt='icon' src={Ellipse4} className='flex items-center justify-center absolute top-0 -z-10' />
                    <img alt='icon' src={IconDashboard1} className='flex items-center justify-center absolute top-[53px] left-[263px] -z-10' />
                    <img alt='icon' src={IconDashboard2} className='flex items-center justify-center absolute top-[261px] left-[138px] -z-10' />
                    <img alt='icon' src={IconDashboard3} className='flex items-center justify-center absolute top-[578px] left-[129px] -z-10' />
                    <img alt='icon' src={IconDashboard4} className='flex items-center justify-center absolute top-[103px] right-[263px] -z-10' />
                    <img alt='icon' src={IconDashboard5} className='flex items-center justify-center absolute top-[421px] right-[193px] -z-10' />
                    <div>
                        <h1>Temukan <span className='text-[#C1121F]'>Karier Baru</span> dan</h1>
                        <h1>Rasakan Kedekatan dengan</h1>
                        <h1><span className='text-[#C1121F]'>Masa Depan</span> yang Anda Inginkan</h1>
                    </div>
                </div>
                <div className='flex text-[20px] text-[#5C5C5C] items-center justify-center text-center mb-[25px]'>
                    <div className=''>
                        <h1>Hubungkan, Berkolaborasi, dan Menciptakan Kesempatan</h1>
                        <h1>Baru di Platform Pencarian Kerja Profesional</h1>
                    </div>
                </div>
                <div>
                    <div className='flex gap-[20px] justify-center items-center px-[312px] mb-[16px]'>
                        <input className='text-[14px] text-[#A8A8A8] py-[21px] px-[27px] border rounded-md outline-none w-full shadow-lg' type="text" placeholder='Cari posisi atau perusahaan disini...' />
                        <button className='py-[18px] px-[35px] text-white bg-[#253166] rounded-lg font-medium'>Search</button>
                    </div>
                    <div className='flex gap-[18px] justify-center mb-[12px]'>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            Content Writer
                        </div>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            Frontedn Developer
                        </div>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            Arsitek
                        </div>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            Quality Control
                        </div>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            Product Designer
                        </div>
                    </div>
                    <div className='flex gap-[18px] justify-center'>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            Account Excutive
                        </div>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            Project Manager
                        </div>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            Content Creator
                        </div>
                        <div className='py-[10px] px-[16px] rounded-xl border-[#226598] border text-[#226598]'>
                            HR
                        </div>
                    </div>
                </div>
                

                {/* <div className='pb-[148px]'>
                    <div className='px-[120px] mb-[54px]'>
                        <h1 className='text-[40px] text-[#253166] font-bold mb-[24px]'>Headline 4 </h1>
                        <p className='text-[20px] text-[#5C5C5C]'>Lorem ipsum dolor sit amet consectetur. Donec odio eu felis senectus in. Duis urna etiam quam integer nam egestas dictumst cursus.</p>
                    </div>
                    <div className='px-[120px]'>
                        <div className='flex items-center gap-[16.32px] mb-[35px]'>
                            <button onClick={ () => navigate('/career')} className='text-[18px] text-[#219EBC] font-semibold'>See More</button>
                            <MdPlayArrow className='text-xl' />
                        </div>
                        <div className='grid grid-cols-12 gap-[20px]'>
                            {Object.values(limitedData).map((data, index) => {
                                const randomIndex = Math.floor(Math.random() * dataColor.length);
                                const randomColor = dataColor[randomIndex];
                                return (
                                    <button onClick={() => navigate('career-detail', {state: {recruitmentId: data.id}})} key={index} className='px-[24px] py-[27px] rounded-[15px] shadow-lg w-full col-span-3'>
                                        <div className='flex flex-col items-start'>
                                            <h1 className={`${randomColor.background} ${randomColor.border} ${randomColor.text} border py-[8px] px-[16px] text-[10px] w-fit rounded-md mb-[20px]`}>{data.type}</h1>
                                            <h1 className='text-[#454545] text-[24px] font-medium mb-[13px] truncate text-start w-3/4'>{data.title}</h1>
                                            <h1 className='text-[#A8A8A8] text-[11px] mb-[39px] text-justify line-clamp-3'>{data.jobDescription}</h1>
                                        </div>
                                        <div className="flex text-[#24A4E0] text-[12px] gap-[8px] items-center">
                                            <MdOutlineWorkOutline />
                                            <h1>{data.position}</h1>
                                        </div>
                                        <div className="flex text-[#24A4E0] text-[12px] gap-[8px] items-center">
                                            <SlLocationPin />
                                            <h1>{data.placement}</h1>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='bg-[#780000] mx-[120px] pt-[43px] pb-[64px] px-[60px] rounded-3xl mb-[]'>
                <h1 className='text-center text-[#FFFFFF] text-[36px] font-[600] mb-[30px]'>Perusahaan Terpercaya Sedang Merekrut</h1>
                <div className='flex items-center justify-center'>
                    <img src={LogoStartup} alt="" />
                </div>
            </div>
            <div>
                <div className='flex pr-[147px] justify-between items-center'>
                    <img src={DashboardImage} className='' alt="" />
                    <div className=''>
                        <h1 className='text-[36px] font-semibold'>Apa itu Humanusia?</h1>
                        <p className='text-[18px] text-[#737373] font-normal'>Humanusia menyediakan dua platform berkualitas tinggi: Humanusia Career dan Humanusia.id. Humanusia Career memberikan kemudahan bagi perekrut dalam mencari kandidat terbaik dan memberikan solusi terbaik bagi pencari kerja dengan menyediakan informasi lengkap tentang lowongan kerja yang tersedia. Sementara itu, Humanusia.id merupakan sistem informasi kepegawaian yang tak tergantikan, yang memainkan peran penting dalam menjaga kelancaran operasional perusahaan dengan efisiensi dan kehandalan yang tak tertandingi.</p>
                    </div>
                </div>
            </div>
            <div className='px-[199px] pb-[64px]'>
                <div className="flex justify-between mb-[30px]">
                    <h1 className='text-[#253166] font-bold text-[36px]'>Urgently Needed!</h1>
                    <div className='flex items-center gap-[22px]'>
                        <button className='text-[18px] font-semibold text-[#219EBC]'>See More</button>
                        <RiArrowRightSFill className='text-3xl' />
                    </div>
                </div>
                <div className='flex gap-[48px]'>
                    {Object.values(dataRecruitment).map((item, idx) =>{
                    // const randomIndex = Math.floor(Math.random() * dataColor.length);
                    // const randomColor = dataColor[randomIndex];
                    return (
                        <button onClick={() => navigate('/career-detail', {state: {recruitmentId: item.id}})} key={idx} className='border rounded-[10px] px-[24px] py-[30px] w-[368px] hover:bg-slate-200'>
                            <div className='flex mb-[20px] relative'>
                                <div className='rounded-full w-[40px] h-[40px] bg-[#F2F7FD] flex items-center justify-center mr-[16px]'>
                                    <HiOutlineOfficeBuilding className='text-[#1E88E5] text-2xl font-bold'/>
                                </div>
                                <div className='text-[14px] font-medium mr-[83px]'>
                                    <h1>{item.title? item.title : '-'}</h1>
                                    <h1 className='text-[#219EBC] truncate text-start'>{item.company? item.company.company_name : '-'}</h1>
                                </div>
                                <BsBookmark className='absolute right-0'/>
                            </div>
                            <div className='flex items-center gap-[12px] mb-[12px]'>
                                <RiMapPinLine className='text-[#457B9D]'/>
                                <h1 className='text-[#424242] truncate text-start'>{item.location? item.location : '-'}</h1>
                            </div>
                            <div className='flex items-center gap-[12px] mb-[20px]'>
                                <PiSuitcaseSimpleBold className='text-[#457B9D]'/>
                                <h1 className='text-[#424242] truncate text-start'>{item.qualification? item.qualification : '-'}</h1>
                            </div>
                            <div className='flex justify-between'>
                                <div className='text-[10px] text-[#737373]'>
                                    <h1 className='text-start'>Apply before {moment(item.expiredDate).format('DD MMMM YYYY')}</h1>
                                    <h1>Recruiter was hiring {dayPublished(item.publishDate)} Days Ago</h1>
                                </div>
                                <div className='rounded-[5px] border flex items-center justify-center p-[7px]'>
                                    <AiOutlineShareAlt className='text-[#1E88E5] text-lg'/>
                                </div>
                            </div>
                        </button>
                    )})}
                </div>
            </div>
            <FooterLandingPage/>
        </div>
    )
}

export default DashboardLandingPage