import React, { useEffect, useState } from 'react'
import NavbarLandingPage from '../../components/NavbarLandingPage'
import FooterLandingPage from '../../components/FooterLandingPage'
import { CompanyLife1, CompanyLife2, CompanyLife3, CompanyLife4, CompanyLife5, CompanyLife6, CompanyLife7, CompanyLife8, CompanyLife9, Pict6 } from '../../assets'
import { CiSearch } from 'react-icons/ci'
import { RiArrowLeftSLine, RiArrowRightSLine, RiMapPinLine } from 'react-icons/ri'
import { IoMdArrowDropdown } from 'react-icons/io'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { BsBookmark } from 'react-icons/bs'
import Api from '../../Api'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../components/Pagination'

const Company = () => {
  const [dataRecruitment, setDataRecruitment] = useState('')

  const navigate = useNavigate()

  const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [dateSearch, setDateSearch] = useState('')
    const [refresh, setRefresh] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

  const getData = async() => {
    try {
      const response = await Api.GetRecruitment()
      setDataRecruitment(response.data.response)
      setCurrentPage(response.data.currentPage)
      setTotalPages(response.data.totalPages)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <div>
        <NavbarLandingPage />
        <div className='bg-[#780000] py-[30px] px-[120px]'>
          <div className='flex gap-[19px] mb-[16px]' >
            <div className='flex bg-white rounded-md items-center px-[30px] gap-[16px] w-full'>
              <CiSearch className='text-[#A8A8A8] text-2xl'/>
              <input type="text" className='w-full py-[21px] outline-none ' />
            </div>
            <div className='flex bg-white rounded-md items-center px-[30px] gap-[16px]'>
              <RiMapPinLine className='text-[#A8A8A8] text-2xl'/>
              <input type="text" className='w-full py-[21px] outline-none ' />
            </div>
            <button className='bg-[#669BBC] py-[18px] px-[35px] text-[18px] font-medium text-white rounded-md'>Search</button>
          </div>
          
          <div className='flex gap-[24px]'>
            <div className='relative w-[98px]'>
              <select className='outline-none text-[16px] text-[#454545] appearance-none py-[10px] px-[16px] border rounded-xl w-[98px]'>
                <option value="">Work </option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
              <IoMdArrowDropdown className='absolute top-[11px] right-3 text-[#2E2E2E ] text-2xl'/>
            </div>

            <div className='border-white border border-l'></div>

            <div className='relative w-[134px]'>
              <select className='outline-none text-[16px] text-[#454545] appearance-none py-[10px] px-[16px] border rounded-xl w-[134px]'>
                <option value="">Post Date </option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
              <IoMdArrowDropdown className='absolute top-[11px] right-3 text-[#2E2E2E ] text-2xl'/>
            </div>

            <div className='relative w-[190px]'>
              <select className='outline-none text-[16px] text-[#454545] appearance-none py-[10px] px-[16px] border rounded-xl w-[190px]'>
                <option value="">Experience Level </option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
              <IoMdArrowDropdown className='absolute top-[11px] right-3 text-[#2E2E2E ] text-2xl'/>
            </div>

            <div className='relative w-[176px]'>
              <select className='outline-none text-[16px] text-[#454545] appearance-none py-[10px] px-[16px] border rounded-xl w-[176px]'>
                <option value="">Employee Type </option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
              <IoMdArrowDropdown className='absolute top-[11px] right-3 text-[#2E2E2E ] text-2xl'/>
            </div>
            <div className='text-[15px] text-white flex items-center'>
              <button>Clear All</button>
            </div>
          </div>
        </div>

        <div className='bg-[#F8F8F8] px-[120px]'>
            <h1 className='text-[14px] font-medium text-[#C1121F] py-[20px]'>90 Result</h1>
            <div className='space-y-[12px] mb-[30px]'>
              {Object.values(dataRecruitment).map((item, idx) => (
                <button onClick={() => navigate('/career-detail', {state: {recruitmentId: item.id}})} key={idx} className='bg-white rounded-md w-3/4 border py-[20px] px-[28px] hover:bg-slate-200'>
                    <div className='flex mb-[20px]  relative '>
                        <div className='rounded-full w-[40px] h-[40px] bg-[#F2F7FD] flex items-center justify-center mr-[16px]'>
                            <HiOutlineOfficeBuilding className='text-[#1E88E5] text-2xl font-bold'/>
                        </div>
                        <div className='text-[14px] font-medium mr-[83px]'>
                            <h1>{item.title}</h1>
                            <h1 className='text-[#219EBC] truncate text-start'>{item.company? item.company.company_name : '-'}</h1>
                        </div>
                        {/* <h1 className='text-[#737373] text-[10px] absolute right-0 top-0'>Active 3 minutes ago</h1> */}
                    </div>
                    <div className='flex items-center gap-[12px] mb-[12px]'>
                        <RiMapPinLine className='text-[#457B9D]'/>
                        <h1 className='text-[#424242] truncate text-[12px] text-start'>{item.location}</h1>
                    </div>
                </button>
              ))}
            </div>
            
            <div className='flex items-center justify-center pb-[62px]'>
              <Pagination
                  currentPage={currentPage} 
                  totalPages={totalPages} 
                  onPageChange={handlePageChange}
                  onPrevChange={handlePrevChange}
                  onNextChange={handleNextChange}
              />
            </div>
        </div>
        <FooterLandingPage />
    </div>
  )
}

export default Company