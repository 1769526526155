import React, { useState } from 'react'
import { BiImageAlt, BiSearch } from 'react-icons/bi'
import { AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import Modal from '../../../components/Modal'
import Sidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'

const CompanyLife = () => {
    const [showAddNewPost, setShowAddNewPost] = useState()
    const [showEditNewPost, setShowEditNewPost] = useState()
  return (
    <div>
        <Modal 
            activeModal={showAddNewPost}
            title={'Add New Post'}
            buttonClose={ () => setShowAddNewPost(!showAddNewPost)}
            width={'551px'}
            content= {
                <div className='space-y-[26px] w-full'>
                    <div className="w-full">
                        <div className='text-[#737373] mb-[26px]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Photo<span className='text-[#780000]'>*</span></h1>
                            <div className="flex items-center justify-center bg-[#D9D9D9] rounded-[3px] w-[271px] h-[179px] flex-col">
                                <BiImageAlt className="text-6xl" />
                                <h1 className="text-center text-[8px] italic">*Acceptable file types are JPG, JPEG and PNG. Max file size is 2 MB.</h1>
                            </div>


                        </div>
                        <div className='text-[#737373] mb-[26px]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Tittle<span className='text-[#780000]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[12px] border rounded-md text-[12px] w-full' placeholder='ex: Loker Instagram'/>
                        </div>
                        <div className='text-[#737373] mb-[26px]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Description *<span className='text-[#780000]'>*</span></h1>
                            <textarea rows={5} className='py-[10px] px-[12px] border rounded-md text-[12px] w-full resize-none' placeholder='ex: Loker Instagram'/>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className='relative pb-[36px] pt-[11px]'>
                            <div className='flex gap-[12px] absolute right-0'>
                                <button onClick={() => setShowAddNewPost(!showAddNewPost)} className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditNewPost}
            title={'Edit New Post'}
            buttonClose={ () => setShowEditNewPost(!showEditNewPost)}
            width={'551px'}
            content= {
                <div className='space-y-[26px]'>
                    <div className="w-full">
                        <div className='text-[#737373] mb-[26px]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Photo<span className='text-[#780000]'>*</span></h1>
                            <div className="flex items-center justify-center bg-[#D9D9D9] rounded-[3px] w-[271px] h-[179px] flex-col relative">
                                <BiImageAlt className="text-6xl" />
                                <h1 className="text-center text-[8px] italic">*Acceptable file types are JPG, JPEG and PNG. Max file size is 2 MB.</h1>
                                <button className='w-[29px] h-[29px] bg-[#24A4E0] rounded-[9px] flex items-center justify-center absolute -top-2 -right-2'>
                                    <HiOutlinePencil className='text-[white] text-lg'/>
                                </button>
                            </div>


                        </div>
                        <div className='text-[#737373] mb-[26px]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Tittle<span className='text-[#780000]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[12px] border rounded-md text-[12px] w-full' placeholder='ex: Loker Instagram'/>
                        </div>
                        <div className='text-[#737373] mb-[26px]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Description *<span className='text-[#780000]'>*</span></h1>
                            <textarea rows={5} className='py-[10px] px-[12px] border rounded-md text-[12px] w-full resize-none' placeholder='ex: Loker Instagram'/>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className='relative pb-[36px] pt-[11px]'>
                            <div className='flex gap-[12px] absolute right-0'>
                                <button onClick={() => setShowEditNewPost(!showEditNewPost)} className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
        <div className='bg-[#EBEFF5] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={false} NameSubNavbar={'Structure'} LinkBack={''} NameNavbar={'Company Life'}/>
                    <div className='bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className="flex items-center justify-between mb-[46px]">
                            <div className=''>
                                <h1 className='text-[#0A0A1F] text-[16px] font-semibold mb-[5px]'>Company Life Galery</h1>
                                <h2 className='text-[#0A0A1F] text-[10px]'>20 Post</h2>
                            </div>
                            <div>
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                    <div className='relative'>
                                        <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                        <input placeholder='Search...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                    </div>
                                    <button onClick={() => setShowAddNewPost(!showAddNewPost)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>New Post </h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="relative overflow-x-auto">
                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead class="text-[10px] text-[#737373] uppercase border-b dark:bg-gray-700 dark:text-gray-400 font-semibold">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Date
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            photo
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Title
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            DESCRIPTION
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            ACTION
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-[#454545] text-[12px]'>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <td class="px-6 py-[29px]">
                                            20/09/2022
                                        </td>
                                        <th scope="row" class="px-6 py-[29px] font-medium  whitespace-nowrap dark:text-white">
                                            <img src="https://cdn.myanimelist.net/images/characters/15/422168.jpg" alt="img" className='w-[43px] h-[43px] rounded-[3px]'/>
                                        </th>
                                        <td class="px-6 py-[29px]">
                                            Freshmag
                                        </td>
                                        <td class="px-6 py-[29px]">
                                        Freshmag adalah produk herbal dengan komposisi utama madu hutan murni dan kunyit yang telah dipercaya dan dibuktikan khasiatnya oleh ribuan orang untuk mengatasi masalah pada lambung. Madu hutan mengandung senyawa sebagai antioksidan yang mampu menangkal radikal bebas. Sedangkan kunyit mengandung kurkumin yang mampu mengatasi berbagai gangguan pada lambung seperti tukak lambung, infeksi pada lambung dan mengatasi perut kembung.
                                        </td>
                                        <td class="px-6 py-[29px]">
                                            <div className='w-full flex items-center gap-[12px]'>
                                                <button onClick={() => setShowEditNewPost(!showEditNewPost)} className='w-[29px] h-[29px] bg-[#24A4E0] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[white] text-lg'/>
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#FF5F5F] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[white] text-lg'/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <td class="px-6 py-[29px]">
                                            20/09/2022
                                        </td>
                                        <th scope="row" class="px-6 py-[29px] font-medium  whitespace-nowrap dark:text-white">
                                            <img src="https://cdn.myanimelist.net/images/characters/15/422168.jpg" alt="img" className='w-[43px] h-[43px] rounded-[3px]'/>
                                        </th>
                                        <td class="px-6 py-[29px]">
                                            Freshmag
                                        </td>
                                        <td class="px-6 py-[29px]">
                                        Freshmag adalah produk herbal dengan komposisi utama madu hutan murni dan kunyit yang telah dipercaya dan dibuktikan khasiatnya oleh ribuan orang untuk mengatasi masalah pada lambung. Madu hutan mengandung senyawa sebagai antioksidan yang mampu menangkal radikal bebas. Sedangkan kunyit mengandung kurkumin yang mampu mengatasi berbagai gangguan pada lambung seperti tukak lambung, infeksi pada lambung dan mengatasi perut kembung.
                                        </td>
                                        <td class="px-6 py-[29px]">
                                            <div className='w-full flex items-center gap-[12px]'>
                                                <button onClick={() => setShowEditNewPost(!showEditNewPost)} className='w-[29px] h-[29px] bg-[#24A4E0] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[white] text-lg'/>
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#FF5F5F] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[white] text-lg'/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyLife