import React from 'react'
import {LogoH} from '../../assets'
import { Link } from 'react-router-dom'

const Login = () => {
  return (
    <div className=" bg-gradient-to-l from-[#780000] to-[#2a0101] h-screen flex items-center justify-center">
        <div>
            <div className='flex items-center'>
                <img src={LogoH} alt="" className='mb-6 w-[105px]' />
                <h1 className='text-[55px] text-[#F4F9F5] font-medium'>Humanusia</h1>
            </div>

            <h1 className='text-[13px] font-medium mb-[15px] text-white'>Email</h1>
            <input type="text" className='py-[20px] px-[27px] text-[16px] font-medium text-[#A8A8A8] border-white border-[1px] rounded-[9px] mb-[30px] bg-transparent w-full' placeholder='ethos.kreafif@example.com'/>

            <h1 className='text-[13px] font-medium mb-[15px] text-white'>Password</h1>
            <input type="password" className='py-[20px] px-[27px] text-[16px] font-medium text-[#A8A8A8] border-[1px]  border-white rounded-[9px] bg-transparent w-full' placeholder='.......'/>

            <br />
            <Link to='/dashboard'>
                <button  className='py-[20px] px-[179px] bg-white rounded-[9px] text-[#2E5660] mt-[66px] font-medium w-full'>Login</button>
            </Link>
        </div>
    </div>

  )
}

export default Login