import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'

const Dashboard = () => {
  return (
    <div>
        <div className='bg-[#EBEFF5] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={false} NameSubNavbar={''} LinkBack={''} NameNavbar={'Dashboard'}/>
                    <div className='flex gap-[40px]'>
                        <div className='p-[25px] rounded-xl border-l-[5px] border-[#253166] bg-white w-[322px]'>
                            <h1 className='text-[32px] font-semibold mb-[4px] text-[#253166]'>869.090</h1>
                            <p className='font-medium text-[#737373] text-[12px]'>Total Applicant</p>
                        </div>
                        <div className='p-[25px] rounded-xl border-l-[5px] border-[#004F9F] bg-white w-[322px]'>
                            <h1 className='text-[32px] font-semibold mb-[4px] text-[#004F9F]'>124</h1>
                            <p className='font-medium text-[#737373] text-[12px]'>Total Active Applications</p>
                        </div>
                        <div className='p-[25px] rounded-xl border-l-[5px] border-[#219EBC] bg-white w-[322px]'>
                            <h1 className='text-[32px] font-semibold mb-[4px] text-[#219EBC]'>74</h1>
                            <p className='font-medium text-[#737373] text-[12px]'>Total Application Ends</p>
                        </div>
                    </div>
                    <div className='flex gap-[20px]'>
                        <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border w-[740px]'>
                            <div className='text-[#0A0A1F]'>
                                <h1 className='text-[16px] font-semibold mb-[5px]'>Galery</h1>
                                <h1 className='text-[10px]'>20 Post</h1>
                            </div>
                            <div class="relative overflow-x-auto">
                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead class="text-[10px] text-[#737373] uppercase border-b dark:bg-gray-700 dark:text-gray-400 font-semibold">
                                    <tr>
                                        <th scope="col" class="px-3 py-3">
                                            DATE
                                        </th>
                                        <th scope="col" class="px-3 py-3">
                                            PHOTO
                                        </th>
                                        <th scope="col" class="px-3 py-3">
                                            TITLE
                                        </th>
                                        <th scope="col" class="px-3 py-3">
                                            DESCRIPTION
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-[#454545] text-[12px]'>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <th scope="row" class="px-3 py-[29px] font-medium  whitespace-nowrap dark:text-white">
                                            20/09/2022
                                        </th>
                                        <td class="px-3 py-[29px]">
                                            <img src="https://cdn.myanimelist.net/images/characters/15/422168.jpg" alt="photo" className='w-[34px] h-[34px] rounded-[3px]' />
                                        </td>
                                        <td class="px-3 py-[29px]">
                                            Lorem ipsum dolor sit 
                                        </td>
                                        <td class="px-3 py-[29px]">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody className='text-[#454545] text-[12px]'>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <th scope="row" class="px-3 py-[29px] font-medium  whitespace-nowrap dark:text-white">
                                            20/09/2022
                                        </th>
                                        <td class="px-3 py-[29px]">
                                            <img src="https://cdn.myanimelist.net/images/characters/15/422168.jpg" alt="photo" className='w-[34px] h-[34px] rounded-[3px]' />
                                        </td>
                                        <td class="px-3 py-[29px]">
                                            Lorem ipsum dolor sit 
                                        </td>
                                        <td class="px-3 py-[29px]">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody className='text-[#454545] text-[12px]'>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <th scope="row" class="px-3 py-[29px] font-medium  whitespace-nowrap dark:text-white">
                                            20/09/2022
                                        </th>
                                        <td class="px-3 py-[29px]">
                                            <img src="https://cdn.myanimelist.net/images/characters/15/422168.jpg" alt="photo" className='w-[34px] h-[34px] rounded-[3px]' />
                                        </td>
                                        <td class="px-3 py-[29px]">
                                            Lorem ipsum dolor sit 
                                        </td>
                                        <td class="px-3 py-[29px]">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className='space-y-[33px] bg-white px-[28px] py-[40px] rounded-[12px] border w-[286px]'>
                            <div className='text-[#0A0A1F]'>
                                <h1 className='text-[16px] font-semibold mb-[5px]'>Active Vacancy</h1>
                                <h1 className='text-[10px]'>20 Vacancy</h1>
                            </div>
                            <div className='space-y-[12px]'>
                                <div className="border rounded-lg py-[9px] px-[14px]">
                                    <h1 className='text-[12px] font-semibold mb-[6px] text-[#0A0A1F]'>Machine Learning Engineering</h1>
                                    <h2 className='text-[9px] text-[#00000080]'>100 applicant</h2>
                                </div>
                                <div className="border rounded-lg py-[9px] px-[14px]">
                                    <h1 className='text-[12px] font-semibold mb-[6px] text-[#0A0A1F]'>UI UX Designer</h1>
                                    <h2 className='text-[9px] text-[#00000080]'>100 applicant</h2>
                                </div>
                                <div className="border rounded-lg py-[9px] px-[14px]">
                                    <h1 className='text-[12px] font-semibold mb-[6px] text-[#0A0A1F]'>Frontend Developer</h1>
                                    <h2 className='text-[9px] text-[#00000080]'>100 applicant</h2>
                                </div>
                                <div className="border rounded-lg py-[9px] px-[14px]">
                                    <h1 className='text-[12px] font-semibold mb-[6px] text-[#0A0A1F]'>Backend Developer</h1>
                                    <h2 className='text-[9px] text-[#00000080]'>100 applicant</h2>
                                </div>
                                <div className="border rounded-lg py-[9px] px-[14px]">
                                    <h1 className='text-[12px] font-semibold mb-[6px] text-[#0A0A1F]'>System Analyst</h1>
                                    <h2 className='text-[9px] text-[#00000080]'>100 applicant</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard