import React, { useState } from 'react'
import { IconUser, IconDashboard, IconCustomContent, IconCompanySetting, IconLogout, Logo, LogoH } from '../../assets'
import { RiArrowRightSLine, RiArrowLeftSLine, RiArrowRightSFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    const [display, setDisplay] = useState(true)

    const [admin, setAdmin] = useState(false)
    const [job, setJob] = useState(false)
    const [organization, setOrganization] = useState(false)

    const [employee, setEmployee] = useState(false)
    const [configuration, setConfiguration] = useState(false)

    const [timeManagement, setTimeManagement] = useState(false)
    const [attendance, setAttendance] = useState(false)
    
    return (
        <div className={`hidden lg:block px-[30px] py-[40px] border bg-white rounded-[20px] shadow-sm sticky top-[40px] h-[710px] ${display ? 'w-[320px]' : 'w-[124px]'} transition-all duration-1000 ease-in-out`}>
        <div className='border-b-2 w-full py-[8px] relative'>
            <Link to={'/dashboard'} className='flex items-center justify-center'>
                <img src={display ? Logo : LogoH} alt='Humanusia' className='h-[60px]'/>
            </Link>
            <button onClick={ () => {setDisplay(!display); setAdmin(false); setJob(false); setOrganization(false); setEmployee(false); setConfiguration(false); setTimeManagement(false); setAttendance(false)} } className='bg-[#147DB4] shadow-md shadow-[#147DB44D] rounded-full w-[28px] h-[28px] absolute -right-11 top-5 flex items-center justify-center'>
                {display ?
                    <RiArrowLeftSLine className='text-xl text-white'/>
                    :
                    <RiArrowRightSLine className='text-xl text-white'/>
                }
            </button>
        </div>
        <div className={`my-[40px] h-[410px] overflow-auto scrollbar-hide flex flex-col ${display ? 'items-start' : 'items-center'}`}>
            <Link to={'/user'} className='flex items-center gap-[16px] mb-[30px]'>
                <img src={IconUser} alt='Humanusia Icon'/>
                {display &&
                    <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500]'>User</h1>
                }
            </Link>
            

            <Link to={'/dashboard'} className='flex items-center gap-[16px] mb-[30px]'>
                <img src={IconDashboard} alt='Humanusia Icon'/>
                {display &&
                    <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500]'>Dashboard</h1>
                }
            </Link>

            <Link to={'/company-setting'} className='flex items-center gap-[16px] mb-[30px]'>
                <img src={IconCompanySetting} alt='Humanusia Icon'/>
                {display &&
                    <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500]'>Company Setting</h1>
                }
            </Link>
            
            {/* <div className={`${employee ? 'min-h-[160px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full mb-[30px] pl-5 overflow-auto scrollbar-hide`}>  */}
                <button onClick={ () => {setEmployee(!employee); setConfiguration(false); setDisplay(true); setAdmin(false); setTimeManagement(false)} } className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} gap-[16px] text-sm ${employee ? 'text-[#780000]' : 'text-[#A8A8A8]'} hover:text-[#780000] font-[500]`}>
                    <div className='flex items-center gap-[16px]'>
                        <img src={IconCustomContent} alt='Humanusia Icon'/>
                        {display &&
                            <h1>Custom Content</h1>
                        }
                    </div>
                    {display && 
                        <RiArrowRightSFill className='text-xl'/>
                    }
                </button>
                <div className={`${employee ? 'min-h-[160px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full mb-[30px] pl-[39px] overflow-auto scrollbar-hide`}>        
                    <Link to={'/dashboard-custom'}>
                        <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Dashboard</h1>
                    </Link>
                    {/* <Link to={''}>
                        <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Reporting Methods</h1>
                    </Link> */}
                    <Link to={'/about-us-custom'}>
                        <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>About Us</h1>
                    </Link>
                    <Link to={'/our-brand-custom'}>
                        <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Our Brand</h1>
                    </Link>
                    <Link to={'/carier-custom'}>
                        <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Carier</h1>
                    </Link>
                    <Link to={'/company-life-custom'}>
                        <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Company Life</h1>
                    </Link>
                </div>
            {/* </div> */}


            {/* <Link to={'/myprofile'} className='flex items-center gap-[16px]'>
                <img src={IconRecruitment} alt='Humanusia Icon'/>
                {display &&
                    <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500]'>My Profile</h1>
                }
            </Link> */}
        </div>
        <div className={`border-t-2 w-full pt-[30px] flex ${display ? 'justify-start' : 'justify-center'}`}>
            <button className='flex items-center gap-[16px]'>
                <img src={IconLogout} alt='Humanusia Icon'/>
                {display &&
                    <h1 className='text-sm text-[#780000] font-[500]'>Sign Out</h1>
                }
            </button>
        </div>
    </div>
  )
}

export default Sidebar