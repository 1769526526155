import React, { useEffect, useState } from 'react'
import NavbarLandingPage from '../../components/NavbarLandingPage'
import FooterLandingPage from '../../components/FooterLandingPage'
import { Mission, Pict1, Pict4, Vector2, Vector3, Vision } from '../../assets'
import { CiSearch } from 'react-icons/ci'
import { TbMinusVertical } from 'react-icons/tb'
import { IoMdArrowDropdown } from 'react-icons/io'
import { RiMapPinLine } from 'react-icons/ri'
import { PiSuitcaseSimpleBold } from 'react-icons/pi'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { BsBookmark } from 'react-icons/bs'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import Api from '../../Api'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const WorkLandingPage = () => {
  const [dataRecruitment, setDataRecruitment] = useState('')
  const [dataRecruitmentDetail, setDataRecruitmentDetail] = useState('')
  const [dataSpesficRecruitment, setDataSpesificRecruitment] = useState('')
  const [idRecruitment, setIdRecruitment] = useState('')
  const [openDetail, setOpenDetail] = useState(false)

  const navigate = useNavigate()

  const getData = async() => {
    try {
      const response = await Api.GetRecruitment()
      setDataRecruitment(response.data.response)
      console.log(response.data.response)
    } catch (error) {
      console.log(error)
    }
  }

  const clickTrigger = async(id) => {
    setOpenDetail(!openDetail)
    setIdRecruitment(id)
    try {
      const response = await Api.GetRecruitmentById(id)
      setDataRecruitmentDetail(response.data[0])
      setDataSpesificRecruitment(response.data[0].spesific_recruitment[0])
      console.log(response.data[0])
    } catch (error) {
      console.log(error)
    }
  }

  const shareClick = () => {
    toast.success('clicked')
    console.log('clicked')
  }

  function dayPublished(published_date) {
    const today = moment(); // Tanggal hari ini
    const publishDate = moment(published_date); // Tanggal publish
  
    const diffInDays = today.diff(publishDate, 'days');
  
    console.log(today.format('DD MM YYYY'), 'hari ini');
    console.log(publishDate.format('DD MM YYYY'), 'hari publish');
    console.log('hasil =', diffInDays);
  
    return diffInDays;
  }


  useEffect(() => {
    getData()
  },[])

  return (
    <div className='bg-[#FCFCFC]'>
        <NavbarLandingPage/>
        <div className='bg-[#780000] py-[30px] px-[120px]'>
          <div className='flex gap-[19px] mb-[16px]' >
            <div className='flex bg-white rounded-md items-center px-[30px] gap-[16px] w-full'>
              <CiSearch className='text-[#A8A8A8] text-2xl'/>
              <input type="text" className='w-4/7 py-[21px] outline-none' placeholder='Search: UI/UX Designer' />
            </div>
            <div className='flex bg-white rounded-md items-center px-[30px] gap-[16px]'>
              <RiMapPinLine className='text-[#A8A8A8] text-2xl' />
              <input type="text" className='w-3/7 py-[21px] outline-none' placeholder='Search: Purwokerto' />
            </div>
            <button className='bg-[#669BBC] py-[18px] px-[35px] text-[18px] font-medium text-white rounded-md'>Search</button>
          </div>
          
          <div className='flex gap-[24px]'>
            <div className='relative'>
              <select className='outline-none text-[16px] text-[#454545] appearance-none py-[10px] px-[16px] border rounded-xl'>
                <option value="">Work </option>
                <option value="">Work From Home</option>
                <option value="">Work From Office</option>
              </select>
              <IoMdArrowDropdown className='absolute top-[11px] right-3 text-[#2E2E2E ] text-2xl'/>
            </div>

            <div className='border-white border border-l'></div>

            <div className='relative'>
              <input type='date' className='outline-none text-[16px] text-[#454545] appearance-none py-[10px] px-[16px] border rounded-xl' />
            </div>

            <div className='relative w-[190px]'>
              <select className='outline-none text-[16px] text-[#454545] appearance-none py-[10px] px-[16px] border rounded-xl w-[190px]'>
                <option value="">Experience Level </option>
                <option value="">1 Year</option>
                <option value="">2 Years</option>
                <option value="">3 Years</option>
                <option value="">4 Years</option>
                <option value="">5 Years</option>
                <option value="">&gt;5 Years</option>
              </select>
              <IoMdArrowDropdown className='absolute top-[11px] right-3 text-[#2E2E2E ] text-2xl'/>
            </div>

            <div className='relative w-[176px]'>
              <select className='outline-none text-[16px] text-[#454545] appearance-none py-[10px] px-[16px] border rounded-xl w-[176px]'>
                <option value="">Employee Type </option>
                <option value="">Full Time</option>
                <option value="">Part TIme</option>
                <option value="">Freelancer</option>
              </select>
              <IoMdArrowDropdown className='absolute top-[11px] right-3 text-[#2E2E2E ] text-2xl'/>
            </div>
            <div className='text-[15px] text-white flex items-center'>
              <button>Clear All</button>
            </div>
          </div>
        </div>
        
        <div className='bg-[#F8F8F8] px-[120px]'>
          <div className='flex gap-[14px] py-3'>
            {/* Left */}
            <div>
              <div className='space-y-[20px]'>
              {Object.values(dataRecruitment).map((item, idx) => (
                <button onClick={() => clickTrigger(item.id)} key={idx} className='border rounded-[10px] px-[24px] py-[30px] bg-white w-full hover:bg-slate-200'>
                    <div className='flex mb-[20px] relative'>
                        <div className='rounded-full w-[40px] h-[40px] bg-[#F2F7FD] flex items-center justify-center mr-[16px] '>
                            <HiOutlineOfficeBuilding className='text-[#1E88E5] text-2xl font-bold'/>
                        </div>
                        <div className='text-[14px] font-medium'>
                            <h1 >{item.title? item.title : '-'}</h1>
                            <h1 className='text-[#219EBC] text-start'>{item.company? item.company.company_name : '-'}</h1>
                        </div>
                        <BsBookmark  className='absolute right-0'/>
                    </div>
                    <div className='flex items-center gap-[12px] mb-[12px]'>
                        <RiMapPinLine className='text-[#457B9D]'/>
                        <h1 className='text-[#424242] line-clamp-3'>{item.location? item.location : '-'}</h1>
                    </div>
                    <div className='flex items-center gap-[12px] mb-[20px]'>
                        <PiSuitcaseSimpleBold className='text-[#457B9D]'/>
                        <h1 className='text-[#424242] line-clamp-3'>{item.qualification? item.qualification : '-'}</h1>
                    </div>
                    <div className='flex justify-between'>
                        <div className='text-[10px] text-[#737373]'>
                            <h1 className='text-start'>Apply before {moment(item.expiredDate).format('DD MMMM YYYY')}</h1>
                            <h1>Recruiter was hiring {dayPublished(item.publishDate)} Days Ago</h1>
                        </div>
                        <button onClick={shareClick} className='rounded-[5px] border flex items-center justify-center p-[7px] z-20'>
                            <AiOutlineShareAlt className='text-[#1E88E5] text-lg'/>
                        </button>
                    </div>
                </button>
              ))}
              </div>
            </div>
            
            {/* Right */}
            {openDetail !== false? (
              <div className='bg-white py-[32px] px-[35px] w-full'>
                <div className='flex w-full'>
                  <div className='flex bg-white mb-[20px] border-b w-full justify-between pb-5'>
                      <div className='flex'>
                        <div className='rounded-full w-[40px] h-[40px] bg-[#F2F7FD] flex items-center justify-center mr-[16px]'>
                            <HiOutlineOfficeBuilding className='text-[#1E88E5] text-2xl font-bold'/>
                        </div>
                        <div className='text-[16px] font-medium mr-[83px]'>
                            <h1>{dataRecruitmentDetail? dataRecruitmentDetail.title : '-'}</h1>
                            <h1 className='text-[#219EBC] truncate'>{dataRecruitmentDetail.company? dataRecruitmentDetail.company.company_name : '-'}</h1>
                        </div>
                      </div>
                      <button onClick={() => navigate('/career-detail', {state: {recruitmentId: dataRecruitmentDetail.id}})} className='flex items-center gap-[18px]'>
                        {/* <BsBookmark className='text-2xl'/> */}
                        <AiOutlineShareAlt className='text-2xl'/>
                        <button className='bg-[#004F9F] text-[16px] py-[10px] px-[20px] text-white rounded-md'>Apply This Job</button>
                      </button>
                  </div>
                </div>

                <div className='text-[#737373] text-[10px] mb-[16px]'>
                  <h1>Apply before {dataRecruitmentDetail? moment(dataRecruitmentDetail.expiredDate).format('DD MMMM YYYY') : '-'}</h1>
                  <h1>Recruiter was hiring {dayPublished(dataRecruitmentDetail.publishDate)} Days Ago</h1>
                </div>

                <div className='text-[12px]'>
                  <div className='flex items-center gap-[12px] mb-[12px]'>
                  <RiMapPinLine className='text-[#457B9D]'/>
                    <h1 className='text-[#424242] truncate'>{dataRecruitmentDetail? dataRecruitmentDetail.position : ''}</h1>
                  </div>
                  <div className='flex items-center gap-[12px] mb-[20px]'>
                      <PiSuitcaseSimpleBold className='text-[#457B9D]'/>
                      <h1 className='text-[#424242]'>{dataRecruitmentDetail? dataRecruitmentDetail.qualification : '-'}</h1>
                  </div>
                </div>

                <div className='mb-[20px]'>
                  <h1 className='text-[#454545] text-[18px]'>Description</h1>
                  <p className='text-[13px] text-[#5C5C5C]'>{dataRecruitmentDetail? dataRecruitmentDetail.jobDescription : '-'}</p>
                  {/* <div className='text-[13px] text-[#5C5C5C]'>
                    <li>{dataRecruitmentDetail? dataRecruitmentDetail.jobDescription : '-'}</li>
                    <li>Design and implement ML/DL solutions and integrate them with various Big Data platforms and architectures</li>
                    <li>Create and maintain ML pipelines that are scalable, robust, and ready for production</li>
                    <li>Collaborate with domain experts, software developers, and data scientists</li>
                    <li>Troubleshoot ML/DL model issues, including recommendations for retrain, re-validate, and improvements/optimization.</li>
                  </div> */}
                </div>

                <div className='mb-[20px]'>
                  <h1 className='text-[#454545] text-[18px]'>Qualification</h1>

                  <div className=' mb-[20px]'>
                    <p className='text-[13px] text-[#5C5C5C]'>Requirements:</p>
                    <p className='text-[13px] text-[#5C5C5C]'>{dataRecruitmentDetail? dataRecruitmentDetail.qualification : '-'}</p>
                    {/* <div className='text-[13px] text-[#5C5C5C]'>
                      <li>Design and implement ML/DL solutions and integrate them with various Big Data platforms and architectures</li>
                      <li>Create and maintain ML pipelines that are scalable, robust, and ready for production</li>
                      <li>Collaborate with domain experts, software developers, and data scientists</li>
                      <li>Troubleshoot ML/DL model issues, including recommendations for retrain, re-validate, and improvements/optimization.</li>
                    </div> */}
                  </div>

                  <div>
                    <p className='text-[13px] text-[#5C5C5C]'>Preffered:</p>
                    <div className='text-[13px] text-[#5C5C5C]'>
                      <li>Range Age: {dataSpesficRecruitment? dataSpesficRecruitment.ageRange : '-'}</li>
                      <li>Gender: {dataSpesficRecruitment? dataSpesficRecruitment.gender : '-'}</li>
                      <li>Education: {dataSpesficRecruitment? dataSpesficRecruitment.education : '-'}</li>
                      <li>Work Experience: {dataSpesficRecruitment? dataSpesficRecruitment.experience : '-'}</li>
                      <li>Skill: {dataSpesficRecruitment? dataSpesficRecruitment.skill : '-'}</li>
                      <li>Priority: {dataSpesficRecruitment? dataSpesficRecruitment.priority : '-'}</li>
                    </div>
                  </div>

                </div>

              </div>
            ) : (
                <div className='bg-white py-[32px] px-[35px] w-full flex items-center justify-center'>
                  <h1 className='text-center text-2xl'>Not available for recruitment ☹️</h1>

                </div>
            )}
          </div>
        </div>

        <FooterLandingPage/>
    </div>
  )
}

export default WorkLandingPage