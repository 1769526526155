import React, { useState } from 'react'
import NavbarLandingPage from '../../../components/NavbarLandingPage'
import FooterLandingPage from '../../../components/FooterLandingPage'
import { BsPlusCircleFill, BsTrash2, BsPencil } from 'react-icons/bs'
import { IoMdArrowDropdown } from 'react-icons/io'
import Modal from '../../../components/Modal'
import { FormInput } from '../../../components'
import moment from 'moment'
import Api from '../../../Api'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'

const FormLamaran = () => {
    const params = useLocation()
    const navigate = useNavigate()
    const [showAddRiwayatPendidikan, setShowAddRiwayatPendidikan] = useState()
    const [showEditRiwayatPendidikan, setShowEditRiwayatPendidikan] = useState()
    const [showAddPengalamanKerja, setShowAddPengalamanKerja] = useState()
    const [showEditPengalamanKerja, setShowEditPengalamanKerja] = useState()

    // Data State
    const [fullname, setFullname] = useState('')
    const [vacancies, setVacancies] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [age, setAge] = useState('')
    const [gender, setGender] = useState('')
    const [npwp, setNPWP] = useState('')
    const [idCard, setIDCard] = useState('')
    const [nationality, setNationality] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [religion, setReligion] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [domicile, setDomicile] = useState('')
    const [existhingSalary, setExisthingSalary] = useState('')
    const [expectedSalary, setExpectedSalary] = useState('')


    const [fileCV, setFileCV] = useState('')
    const [fileIDCard, setFileIDCard] = useState('')
    const [fileFamilyCard, setFileFamilyCard] = useState('')
    const [fileLastDiploma, setFileLastDiploma] = useState('')
    const [fileTranscripts, setFileTranscripts] = useState('')
    const [fileVaccine, setFileVaccine] = useState('')
    const [filePortofolio, setFilePortofolio] = useState('')
    const [fileApplicant, setFileApplicant] = useState('')

    // Education History
    const [educationalBG, setEducationalBG] = useState([]) 
    const [institutionName, setInstitutionName] = useState('')
    const [fieldOfStudy, setFieldOfStudy] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [grade, setGrade] = useState('')
    const [editIndexEducation, setEditIndexEducation] = useState(-1);
    const [editedDataEducation, setEditedDataEducation] = useState({
        InstitutionName: institutionName,
        FieldOfStudy: fieldOfStudy,
        startDate: startDate,
        endDate: endDate,
        grade: grade
    });
    
    // Work Experience
    const [workExperienceBG, setWorkExperienceBG] = useState([]) 
    const [position, setPosition] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [startDateWork, setStartDateWork] = useState('')
    const [endDateWork, setEndDateWork] = useState('')
    const [typeOfWork, setTypeOfWork] = useState('')
    const [descriptionWork, setDescriptionWork] = useState('')
    const [editIndexWorkExperience, setEditIndexWorkExperience] = useState(-1);
    const [editedDataWorkExperience, setEditedDataWorkExperience] = useState({
        position: position,
        companyName: companyName,
        startDateWork: startDateWork,
        endDateWork: endDateWork,
        typeOfWork: typeOfWork,
        descriptionWork: descriptionWork
    });

    // Options
    const vacanciesOptions = [
        {value: '', label: 'Select Job Source'},
        {value: 'Humanusia', label: 'Humanusia'},
        {value: 'Linkedin', label: 'Linkedin'},
        {value: 'Glints', label: 'Glints'},
        {value: 'Twitter', label: 'Twitter'},
        {value: 'Job Seeker', label: 'Job Seeker'},
    ]
    const genderOptions = [
        {value: '', label: 'Select Your Gender'},
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'}
    ]
    const nationalityOptions = [
        {value: '', label: 'Select Your Nationality'},
        {value: 'WNI', label: 'WNI'},
        {value: 'WNA', label: 'WNA'}
    ]
    const maritalStatusOptions = [
        {value: '', label: 'Select Your Marital Status'},
        {value: 'Single', label: 'Single'},
        {value: 'Married', label: 'Married'},
        {value: 'Widow/Widowed', label: 'Widow/Widowed'}
    ]
    const religionOptions = [
        {value: '', label: 'Select Your Religion'},
        {value: 'Islam', label: 'Islam'},
        {value: 'Christian', label: 'Christian'},
        {value: 'Protestant', label: 'Protestant'},
        {value: 'Catholic', label: 'Catholic'},
        {value: 'Confucius', label: 'Confucius'},
        {value: 'Buddha', label: 'Buddha'},
        {value: 'Hindu', label: 'Hindu'},
        {value: 'Jewish', label: 'Jewish'},
    ]

    // Educational History

    const handleEducationalBackgroundSubmit = () => {
        const newData = { 
            InstitutionName: institutionName,
            FieldOfStudy: fieldOfStudy,
            startDate: startDate,
            endDate: endDate,
            grade: grade
        };
        setEducationalBG(prevData => [...prevData, newData]);
        toast.success('Success Add Education!')
        setShowAddRiwayatPendidikan(!showAddRiwayatPendidikan)
        
    };

    const handleEducationalBackgroundRemove = (index) => {
        setEducationalBG(prevData => prevData.filter((_, i) => i !== index));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditedDataEducation(prevData => ({ ...prevData, [name]: value }));
    };
    
    const editData = (index) => {
        setShowEditRiwayatPendidikan(!showEditRiwayatPendidikan)
        const itemToEdit = educationalBG[index];
        setEditIndexEducation(index);
        setEditedDataEducation(itemToEdit);
    };
    
    const saveEditedData = () => {
        setEducationalBG(prevData => {
            const updatedData = [...prevData];
            updatedData[editIndexEducation] = editedDataEducation;
            return updatedData;
        });
        setEditIndexEducation(-1);
        setEditedDataEducation({
            InstitutionName: '',
            FieldOfStudy: '',
            startDate: '',
            endDate: '',
            grade: ''
        });
        toast.success('Success Edit Education!')
        setShowEditRiwayatPendidikan(!showEditRiwayatPendidikan)
    };


    // Work Experience

    const handleWorkExpSubmit = () => {
        const newData = { 
            position: position,
            companyName: companyName,
            startDateWork: startDateWork,
            endDateWork: endDateWork,
            typeOfWork: typeOfWork,
            descriptionWork: descriptionWork
        };
        setWorkExperienceBG(prevData => [...prevData, newData]);
        toast.success('Success Add Work Experience')
        setShowAddPengalamanKerja(!showAddPengalamanKerja)
        
    };

    const handleWorkExpRemove = (index) => {
        setWorkExperienceBG(prevData => prevData.filter((_, i) => i !== index));
    };

    const handleInputChangeWorkExp = (event) => {
        const { name, value } = event.target;
        setEditedDataWorkExperience(prevData => ({ ...prevData, [name]: value }));
    };
    
    const editDataWorkExp = (index) => {
        setShowEditPengalamanKerja(!showEditPengalamanKerja)
        const itemToEdit = workExperienceBG[index];
        setEditIndexWorkExperience(index);
        setEditedDataWorkExperience(itemToEdit);
    };
    
    const saveEditedDataWorkExp = () => {
        setWorkExperienceBG(prevData => {
            const updatedData = [...prevData];
            updatedData[editIndexWorkExperience] = editedDataWorkExperience;
            return updatedData;
        });
        setEditIndexWorkExperience(-1);
        setEditedDataWorkExperience({
            position: '',
            companyName: '',
            startDateWork: '',
            endDateWork: '',
            typeOfWork: '',
            descriptionWork: ''
        });
        toast.success('Success Edit Work Experience')
        setShowEditPengalamanKerja(!showEditPengalamanKerja)
    };

    console.log(params.state.recruitmentId)

    const PostData = async() => {
        try {
            const data = {
                recruitmentId: params.state.recruitmentId,
                source: vacancies,
                date: new Date(),
                name: fullname,
                gender: gender,
                phone: phone,
                identityAddress: address,
                identityNumber: idCard,
                religion: religion,
                npwp: npwp,
                address: domicile,
                email: email,
                birthDate: birthdate,
                age: age,
                citizen: nationality,
                experience: JSON.stringify(workExperienceBG),
                educations: JSON.stringify(educationalBG),
                martial: maritalStatus,
                kartuKeluarga: fileFamilyCard,
                applicantFile: fileApplicant,
                ktp: fileIDCard,
                ijazah: fileLastDiploma,
                transkipNilai: fileTranscripts,
                portofolio: filePortofolio,
                vaccince: fileVaccine,
                last_salary: existhingSalary,
                expected_salary: expectedSalary,
                status: 'pending',
                title: params.state.position
            }
            const response = await Api.PostAplicant(data)
            toast.success('Success Post Applicant')
            navigate('/form-lamaran-success')
        } catch (error) {
            console.log(error)
            toast.error('Failed Post Applicant!!')
        }
    }

    return (
        <div>
        <Modal
            activeModal={showAddRiwayatPendidikan}
            buttonClose={() => setShowAddRiwayatPendidikan(!showAddRiwayatPendidikan)}
            width={'715px'}
            title={'Add Education'}
            content={
                <div className='space-y-[25px] w-full'>
                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Institution Name <span className='text-[#C1121F]'>*</span></h1>
                        <input name='InstitutionName' onChange={(e) => setInstitutionName(e.target.value)} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Nama instansi...' />
                    </div>

                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Field Of Study <span className='text-[#C1121F]'>*</span></h1>
                        <input name='FieldOfStudy' onChange={(e) => setFieldOfStudy(e.target.value)} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Bidang studi anda...' />
                    </div>

                    <div className="flex gap-[27px]">
                        <div className = 'w-full'>
                            <h1 className='text-[#737373] text-[11px]'>Start Date <span className='text-[#C1121F]'>*</span></h1>
                            <input name='startDate' onChange={(e) => setStartDate(e.target.value)} type="date" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Masukan nama lengkap anda...' />
                        </div>
                        
                        <div className = 'w-full'>
                            <h1 className='text-[#737373] text-[11px]'>End Date <span className='text-[#C1121F]'>*</span></h1>
                            <input name='endDate' onChange={(e) => setEndDate(e.target.value)} type="date" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Masukan nama lengkap anda...' />
                        </div>
                    </div>

                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Grade</h1>
                        <input name='grade' onChange={(e) => setGrade(e.target.value)} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Nilai akhir rata-rata atau IPK...' />
                    </div>

                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowAddRiwayatPendidikan(!showAddRiwayatPendidikan)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={handleEducationalBackgroundSubmit} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Add</button>
                    </div>
                </div>
            }
        />
        <Modal
            activeModal={showEditRiwayatPendidikan}
            buttonClose={() => setShowEditRiwayatPendidikan(!showEditRiwayatPendidikan)}
            width={'715px'}
            title={'Edit Education'}
            content={
                <div className='space-y-[25px] w-full'>
                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Institution Name <span className='text-[#C1121F]'>*</span></h1>
                        <input value={editedDataEducation.InstitutionName} name='InstitutionName' onChange={handleInputChange} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Nama instansi...' />
                    </div>

                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Field Of Study <span className='text-[#C1121F]'>*</span></h1>
                        <input value={editedDataEducation.FieldOfStudy} name='FieldOfStudy' onChange={handleInputChange} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Bidang studi anda...' />
                    </div>

                    <div className="flex gap-[27px]">
                        <div className = 'w-full'>
                            <h1 className='text-[#737373] text-[11px]'>Start Date <span className='text-[#C1121F]'>*</span></h1>
                            <input value={editedDataEducation.startDate} name='startDate' onChange={handleInputChange} type="date" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Masukan nama lengkap anda...' />
                        </div>
                        
                        <div className = 'w-full'>
                            <h1 className='text-[#737373] text-[11px]'>End Date <span className='text-[#C1121F]'>*</span></h1>
                            <input value={editedDataEducation.endDate} name='endDate' onChange={handleInputChange} type="date" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Masukan nama lengkap anda...' />
                        </div>
                    </div>

                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Grade</h1>
                        <input value={editedDataEducation.grade} name='grade' onChange={handleInputChange} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Nilai akhir rata-rata atau IPK...' />
                    </div>

                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowEditRiwayatPendidikan(!showEditRiwayatPendidikan)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={saveEditedData} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                    </div>
                </div>
            }
        />
        <Modal
            activeModal={showAddPengalamanKerja}
            buttonClose={() => setShowAddPengalamanKerja(!showAddPengalamanKerja)}
            width={'550px'}
            title={'Add Work Experience'}
            content={
                <div className='space-y-[25px] w-full'>
                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Position <span className='text-[#C1121F]'>*</span></h1>
                        <input name='position' onChange={(e) => setPosition(e.target.value)} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Nama instansi...' />
                    </div>

                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Company <span className='text-[#C1121F]'>*</span></h1>
                        <input name='companyName' onChange={(e) => setCompanyName(e.target.value)} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Bidang studi anda...' />
                    </div>

                    <div className="flex gap-[27px]">
                        <div className = 'w-full'>
                            <h1 className='text-[#737373] text-[11px]'>Start Date <span className='text-[#C1121F]'>*</span></h1>
                            <input name='startDateWork' onChange={(e) => setStartDateWork(e.target.value)} type="date" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Masukan nama lengkap anda...' />
                        </div>
                        
                        <div className = 'w-full'>
                            <h1 className='text-[#737373] text-[11px]'>End Date <span className='text-[#C1121F]'>*</span></h1>
                            <input name='endDateWork' onChange={(e) => setEndDateWork(e.target.value)} type="date" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Masukan nama lengkap anda...' />
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[#737373] text-[11px]'>Type Of Work <span className='text-[#C1121F]'>*</span></h1>
                        <div className="w-full">
                            <div className='relative'>
                                <select name='typeOfWork' onChange={(e) => setTypeOfWork(e.target.value)} className={`border rounded-[6px] text-[#A8A8A8] text-[12px] px-[12px] py-[10px] w-full appearance-none`}>
                                    <option selected value={''} disabled>Pilih Jenis Pekerjaan</option>
                                    <option value={'Fulltime'}>Fulltime</option>
                                    <option value={'Partime'}>Partime</option>
                                    <option value={'Internship'}>Internship</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                    </div>

                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Description</h1>
                        <textarea name='descriptionWork' onChange={(e) => setDescriptionWork(e.target.value)} rows={4} className='border rounded-md py-[12px] px-[12px] text-[12px] w-full resize-none' placeholder='Description' />
                    </div>

                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowAddPengalamanKerja(!showAddPengalamanKerja)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={handleWorkExpSubmit} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Add</button>
                    </div>
                </div>
            }
        />
        <Modal
            activeModal={showEditPengalamanKerja}
            buttonClose={() => setShowEditPengalamanKerja(!showEditPengalamanKerja)}
            width={'550px'}
            title={'Edit Work Experience'}
            content={
                <div className='space-y-[25px] w-full'>
                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Position <span className='text-[#C1121F]'>*</span></h1>
                        <input value={editedDataWorkExperience.position} name='position' onChange={handleInputChangeWorkExp} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Nama instansi...' />
                    </div>

                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Company <span className='text-[#C1121F]'>*</span></h1>
                        <input value={editedDataWorkExperience.companyName} name='companyName' onChange={handleInputChangeWorkExp} type="text" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Bidang studi anda...' />
                    </div>

                    <div className="flex gap-[27px]">
                        <div className = 'w-full'>
                            <h1 className='text-[#737373] text-[11px]'>Start Date <span className='text-[#C1121F]'>*</span></h1>
                            <input value={editedDataWorkExperience.startDateWork} name='startDateWork' onChange={handleInputChangeWorkExp} type="date" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Masukan nama lengkap anda...' />
                        </div>
                        
                        <div className = 'w-full'>
                            <h1 className='text-[#737373] text-[11px]'>End Date <span className='text-[#C1121F]'>*</span></h1>
                            <input value={editedDataWorkExperience.endDateWork} name='endDateWork' onChange={handleInputChangeWorkExp} type="date" className='border rounded-md py-[12px] px-[12px] text-[12px] w-full' placeholder='Masukan nama lengkap anda...' />
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[#737373] text-[11px]'>Type Of Work <span className='text-[#C1121F]'>*</span></h1>
                        <div className="w-full">
                            <div className='relative'>
                                <select value={editedDataWorkExperience.typeOfWork} name='typeOfWork' onChange={handleInputChangeWorkExp} className={`border rounded-[6px] text-[#A8A8A8] text-[12px] px-[12px] py-[10px] w-full appearance-none`}>
                                    <option selected value={''} disabled>Pilih Jenis Pekerjaan</option>
                                    <option value={'Fulltime'}>Fulltime</option>
                                    <option value={'Partime'}>Partime</option>
                                    <option value={'Internship'}>Internship</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                    </div>

                    <div className = 'w-full'>
                        <h1 className='text-[#737373] text-[11px]'>Description</h1>
                        <textarea value={editedDataWorkExperience.descriptionWork} name='descriptionWork' onChange={handleInputChangeWorkExp} rows={4} className='border rounded-md py-[12px] px-[12px] text-[12px] w-full resize-none' placeholder='Description' />
                    </div>

                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowEditPengalamanKerja(!showEditPengalamanKerja)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={saveEditedDataWorkExp} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                    </div>
                </div>
            }
        />
            <NavbarLandingPage/>
            <div className='bg-[#F6FBFF] px-[120px] py-[50px] space-y-[37px]'>
                <div className='bg-white rounded-[12px] border shadow-lg px-[42px] py-[32px] space-y-[30px]'>
                    <h1 className='text-[#454545] font-semibold'>Application Data</h1>
                    <div className='space-y-[30px]'>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'Fullname'}
                                placeholder={'Input Your Fullname'}
                                onChange={ (e) => setFullname(e.target.value)}
                            />
                            <FormInput
                                title={'Source of Vacancies'}
                                placeholder={'Select Job Source'}
                                onChange={ (e) => setVacancies(e.target.value)}
                                fieldType={'dropdown'}
                                option={vacanciesOptions}
                            />
                        </div>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'Birthdate'}
                                type={'date'}
                                onChange={ (e) => setBirthdate(e.target.value)}
                            />
                            <FormInput
                                title={'Age'}
                                placeholder={'Input Your Age..'}
                                onChange={ (e) => setAge(e.target.value)}
                                type={'number'}
                            />
                        </div>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'Gender'}
                                placeholder={'Input Your Gender'}
                                onChange={ (e) => setGender(e.target.value)}
                                fieldType={'dropdown'}
                                option={genderOptions}
                            />
                            <FormInput
                                title={'NPWP'}
                                placeholder={'xx.xxx.xxx.x-xxx.xxx'}
                                onChange={ (e) => setNPWP(e.target.value)}
                            />
                        </div>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'ID Card Number'}
                                placeholder={'Input Your ID Card Number..'}
                                onChange={ (e) => setIDCard(e.target.value)}
                                type={'number'}
                            />
                            <FormInput
                                title={'Nationality'}
                                placeholder={'Input Your Nationality'}
                                onChange={ (e) => setNationality(e.target.value)}
                                fieldType={'dropdown'}
                                option={nationalityOptions}
                            />
                        </div>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'Marital Status'}
                                placeholder={'Input Your Marital Status'}
                                onChange={ (e) => setMaritalStatus(e.target.value)}
                                fieldType={'dropdown'}
                                option={maritalStatusOptions}
                            />
                            <FormInput
                                title={'Religion'}
                                placeholder={'Input Your Religion'}
                                onChange={ (e) => setReligion(e.target.value)}
                                fieldType={'dropdown'}
                                option={religionOptions}
                            />
                        </div>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'Email'}
                                placeholder={'Input Your Email..'}
                                onChange={ (e) => setEmail(e.target.value)}
                                type={'email'}
                            />
                            <FormInput
                                title={'Phone Number'}
                                placeholder={'Input Your Phone Number..'}
                                onChange={ (e) => setPhone(e.target.value)}
                                type={'number'}
                            />
                        </div>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'KTP Address'}
                                placeholder={'Input Your KTP Address..'}
                                onChange={ (e) => setAddress(e.target.value)}
                                fieldType={'textarea'}
                            />
                        </div>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'Residence Address'}
                                placeholder={'Input Your Residence Address..'}
                                onChange={ (e) => setDomicile(e.target.value)}
                                fieldType={'textarea'}
                            />
                        </div>
                        <div className='flex items-center gap-[20px] w-full'>
                            <FormInput
                                title={'Existhing Salary'}
                                placeholder={'Input Your Existhing Salary..'}
                                onChange={ (e) => setExisthingSalary(e.target.value)}
                                type={'number'}
                            />
                            <FormInput
                                title={'Expected  Salary'}
                                placeholder={'Input Your Expected  Salary..'}
                                onChange={ (e) => setExpectedSalary(e.target.value)}
                                type={'number'}
                            />
                        </div>
                    </div>
                </div>
                <div className='bg-white rounded-[12px] border shadow-lg px-[42px] py-[32px] space-y-[30px]'>
                    <h1 className='text-[#454545] font-semibold'>Educational Background <span className='text-[11px] text-[#C1121F]'>*</span></h1>
                    {educationalBG.length === 0 ?
                    <h1 className='text-[#CACACA] text-center'>Make sure this section is filled in to increase your chances of getting an interview!</h1>
                    :
                    educationalBG.map((data, index) => {
                        return (
                            <div key={index} className='flex items-center justify-between border-b border-[#EDEDED] py-[20px]'>
                                <div className='flex items-start gap-4'>
                                    <h1 className='text-[#454545] text-sm'>{moment(data.startDate).format('YYYY')} - {moment(data.endDate).format('YYYY')}</h1>
                                    <div>
                                        <h1 className='text-[#454545] text-sm'>{data.InstitutionName}</h1>
                                        <h1 className='text-[#A8A8A8] text-sm'>{data.FieldOfStudy}</h1>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4'>
                                    <button>
                                        <BsPencil onClick={() => editData(index)} className='text-sm text-[#003049]'/>
                                    </button>
                                    <button onClick={() => handleEducationalBackgroundRemove(index)}>
                                        <BsTrash2 className='text-sm text-[#003049]'/>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                    }
                    <div className='w-full items-center justify-center flex'>
                        <button onClick={() => setShowAddRiwayatPendidikan(!showAddRiwayatPendidikan)} className='text-[#226598] font-semibold flex items-center gap-4'>
                            <BsPlusCircleFill/>
                            Add Education History
                        </button>
                    </div>
                </div>
                <div className='bg-white rounded-[12px] border shadow-lg px-[42px] py-[32px] space-y-[30px]'>
                    <h1 className='text-[#454545] font-semibold'>Work Experience <span className='text-[11px] text-[#C1121F]'>*</span></h1>
                    {workExperienceBG.length === 0 ?
                    <h1 className='text-[#CACACA] text-center'>Make sure this section is filled in to increase your chances of getting an interview!</h1>
                    :
                    workExperienceBG.map((data, index) => {
                        return (
                            <div key={index} className='flex items-center justify-between border-b border-[#EDEDED] py-[20px]'>
                                <div className='flex items-start gap-4'>
                                    <h1 className='text-[#454545] text-sm'>{moment(data.startDateWork).format('MMMM-YYYY')} - {moment(data.endDateWork).format('MMMM-YYYY')}</h1>
                                    <div>
                                        <h1 className='text-[#454545] text-sm'>{data.position}</h1>
                                        <h1 className='text-[#A8A8A8] text-sm'>{data.companyName}</h1>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4'>
                                    <button>
                                        <BsPencil onClick={() => editDataWorkExp(index)} className='text-sm text-[#003049]'/>
                                    </button>
                                    <button onClick={() => handleWorkExpRemove(index)}>
                                        <BsTrash2 className='text-sm text-[#003049]'/>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                    }
                    <div className='w-full items-center justify-center flex'>
                        <button onClick={() => setShowAddPengalamanKerja(!showAddPengalamanKerja)} className='text-[#226598] font-semibold flex items-center gap-4'>
                            <BsPlusCircleFill/>
                            Add Work Experience
                        </button>
                    </div>
                </div>
                <FormInput
                    fieldType={'file'}
                    title={'Recent CV'}
                    onChange={(e) => setFileCV(e.target.files[0])}
                    htmlFor={'CV'}
                    checkFile={fileCV.name}
                />
                <FormInput
                    fieldType={'file'}
                    title={'ID Card'}
                    onChange={(e) => setFileIDCard(e.target.files[0])}
                    htmlFor={'ID Card'}
                    checkFile={fileIDCard.name}
                />
                <FormInput
                    fieldType={'file'}
                    title={'Family Card'}
                    onChange={(e) => setFileFamilyCard(e.target.files[0])}
                    htmlFor={'Family Card'}
                    checkFile={fileFamilyCard.name}
                />
                <FormInput
                    fieldType={'file'}
                    title={'Last Diploma'}
                    onChange={(e) => setFileLastDiploma(e.target.files[0])}
                    htmlFor={'Last Diploma'}
                    checkFile={fileLastDiploma.name}
                />
                <FormInput
                    fieldType={'file'}
                    title={'Transcripts'}
                    onChange={(e) => setFileTranscripts(e.target.files[0])}
                    htmlFor={'Transcripts'}
                    checkFile={fileTranscripts.name}
                />
                <FormInput
                    fieldType={'file'}
                    title={'Final Vaccine Certificate'}
                    onChange={(e) => setFileVaccine(e.target.files[0])}
                    htmlFor={'Final Vaccine Certificate'}
                    checkFile={fileVaccine.name}
                />
                <FormInput
                    fieldType={'file'}
                    title={'Portfolio'}
                    onChange={(e) => setFilePortofolio(e.target.files[0])}
                    htmlFor={'Portfolio'}
                    checkFile={filePortofolio.name}
                />
                <FormInput
                    fieldType={'file'}
                    title={'Applicant'}
                    onChange={(e) => setFileApplicant(e.target.files[0])}
                    htmlFor={'Applicant'}
                    checkFile={fileApplicant.name}
                />
            
                <div className='flex items-center justify-end gap-4'>
                    <button onClick={ () => navigate(-1)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                    <button onClick={PostData} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Apply</button>
                </div>

            </div>
            <FooterLandingPage/>
        </div>
    )
}

export default FormLamaran