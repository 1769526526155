import React, { useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'

const CustomDashboard = () => {
    const [edit, setEdit] = useState(true)
    const [edit2, setEdit2] = useState(true)
    const [edit3, setEdit3] = useState(true)
  return (
    <div>
        <div className='bg-[#EBEFF5] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={false} NameSubNavbar={'Structure'} LinkBack={''} NameNavbar={'Dashboard'}/>
                    <div>
                        <h1 className='text-[20px] font-semibold  text-[#2E2E2E]'>Custom Content Dashboard</h1>
                        <h2 className='text-[12px] text-[#737373]'>Custom content in dashboard page</h2>
                    </div>

                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        {edit ? (
                            <>
                                <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Headline 1</h1>
                                <p className='text-[#5C5C5C] text-[14px]'>CONTENT 1 - Let's get acquainted more deeply with Our Company. Companies engaged in Digital Online Marketing open job opportunities for job seekers in the Cilacap, Purwokerto and surrounding areas.</p>
                                <div className='flex gap-[30px]'>
                                    <button onClick={() => setEdit(!edit)} className='text-[12px] font-medium text-[#219EBC]'>Edit</button>
                                    <button className='text-[12px] font-medium text-[#C1121F]'>Delete</button>
                                </div>
                            </>
                        ) : (
                            <>
                            <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Edit Headline 1</h1>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C] font-medium text-[12px]'>Heading 1</h1>
                                <input type="text" className='py-[10px] px-[14px] border rounded-md w-3/4' placeholder='Input heading 1...'/>
                            </div>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C]'>Content 1</h1>
                                <textarea rows={4} className='py-[10px] px-[14px] border rounded-md resize-none w-3/4' placeholder=''/>
                            </div>
                            <div className='relative pb-[36px] pt-[11px]'>
                                <div className='flex gap-[12px] absolute right-0'>
                                    <button onClick={() => setEdit(!edit)}   className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                    <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Save</button>
                                </div>
                            </div>
                            </>
                        )}
                    </div>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        {edit2 ? (
                            <>
                                <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Headline 2</h1>
                                <p className='text-[#5C5C5C] text-[14px]'>CONTENT 2 - Let's get acquainted more deeply with Our Company. Companies engaged in Digital Online Marketing open job opportunities for job seekers in the Cilacap, Purwokerto and surrounding areas.</p>
                                <div className='flex gap-[30px]'>
                                    <button onClick={() => setEdit2(!edit2)} className='text-[12px] font-medium text-[#219EBC]'>Edit</button>
                                    <button className='text-[12px] font-medium text-[#C1121F]'>Delete</button>
                                </div>
                            </>
                        ) : (
                            <>
                            <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Edit Headline 2</h1>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C] font-medium text-[12px]'>Heading 2</h1>
                                <input type="text" className='py-[10px] px-[14px] border rounded-md w-3/4' placeholder='Input heading 2...'/>
                            </div>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C]'>Content 2</h1>
                                <textarea rows={4} className='py-[10px] px-[14px] border rounded-md resize-none w-3/4' placeholder=''/>
                            </div>
                            <div className='relative pb-[36px] pt-[11px]'>
                                <div className='flex gap-[12px] absolute right-0'>
                                    <button onClick={() => setEdit2(!edit2)}   className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                    <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Save</button>
                                </div>
                            </div>
                            </>
                        )}
                    </div>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        {edit3 ? (
                            <>
                                <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Headline 3</h1>
                                <p className='text-[#5C5C5C] text-[14px]'>CONTENT 3 - Let's get acquainted more deeply with Our Company. Companies engaged in Digital Online Marketing open job opportunities for job seekers in the Cilacap, Purwokerto and surrounding areas.</p>
                                <div className='flex gap-[30px]'>
                                    <button onClick={() => setEdit3(!edit3)} className='text-[12px] font-medium text-[#219EBC]'>Edit</button>
                                    <button className='text-[12px] font-medium text-[#C1121F]'>Delete</button>
                                </div>
                            </>
                        ) : (
                            <>
                            <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Edit Headline 3</h1>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C] font-medium text-[12px]'>Heading 3</h1>
                                <input type="text" className='py-[10px] px-[14px] border rounded-md w-3/4' placeholder='Input heading 3...'/>
                            </div>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C]'>Content 3</h1>
                                <textarea rows={4} className='py-[10px] px-[14px] border rounded-md resize-none w-3/4' placeholder=''/>
                            </div>
                            <div className='relative pb-[36px] pt-[11px]'>
                                <div className='flex gap-[12px] absolute right-0'>
                                    <button onClick={() => setEdit3(!edit3)}   className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                    <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Save</button>
                                </div>
                            </div>
                            </>
                        )}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default CustomDashboard