import React from 'react'
import NavbarLandingPage from '../../components/NavbarLandingPage'
import FooterLandingPage from '../../components/FooterLandingPage'
import { Brand1, Brand2, Brand3, Brand4, Brand5, Brand6, Pict5 } from '../../assets'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdOutlineWorkOutline } from 'react-icons/md'
import { SlLocationPin } from 'react-icons/sl'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import Api from '../../Api'

const CarierLandingPage = () => {

    const navigate = useNavigate()
    const [dataRecruitment, setDataRecruitment] = useState('')

    const GetRecruitment = async() => {
        try {
            const response = await Api.GetRecruitment()
            setDataRecruitment(response.data.response)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const dataColor = [
        { background: 'bg-[#9FCBE78A]', border: 'border-[#0095E2]', text: 'text-[#0095E2]' },
        { background: 'bg-[#E79F9F8A]', border: 'border-[#780000]', text: 'text-[#780000]' },
        { background: 'bg-[#B19FE78A]', border: 'border-[#9747FF]', text: 'text-[#9747FF]' },
        { background: 'bg-[#FEEACB8A]', border: 'border-[#DC891A]', text: 'text-[#DC891A]' }
    ];

    useEffect(() => {
        GetRecruitment()
    }, [])
    
    return (
        <div>
            <NavbarLandingPage/>
            <div className='px-[124px] pt-[75px] pb-[44px]'>
                <h1 className='text-[#5C5C5C] font-bold text-[40px]'><span className='text-[#199DE0]'>Jadilah</span> Bagian Dari <span><br /></span> Tim <span className='text-[#199DE0]'>Ethos Kreatif</span> Indonesia</h1>
                <p className='text-[#A8A8A8] text-[20px] mb-[65px]'>Perjalanan anda dimulai dari sini</p>

                <div className='flex gap-[20px] mb-[25px]'>
                    <input className='border rounded-[9px] py-[21px] px-[27px] text-[#A8A8A8] text-[14px] w-3/4 shadow-md' placeholder='Cari Disini...'/>
                    <button className='text-[18px] py-[18px] px-[35px] bg-[#253166] text-white rounded-md shadow-md'>Search</button>
                </div>
                <div className='flex gap-[45px]'>
                    <div className='flex items-center relative text-[#A8A8A8]'>
                        <select className='appearance-none bg-transparent outline-none text-[15px] px-2'>
                            <option selected value="">Lokasi</option>
                            <option value="">Cilacap</option>
                            <option value="">Purwokerto</option>
                        </select>
                        <IoMdArrowDropdown className='text-xl absolute right-0' />
                    </div>
                    <div className='flex items-center relative text-[#A8A8A8]'>
                        <select className='appearance-none bg-transparent outline-none text-[15px] px-2'>
                            <option selected value="">Departemen</option>
                            <option value="">General Affairs & Legal </option>
                            <option value="">Marketing</option>
                            <option value="">Operasional</option>
                            <option value="">Human Resources</option>
                            <option value="">Finance & Administration</option>
                            <option value="">Information Technology</option>
                        </select>
                        <IoMdArrowDropdown className='text-xl absolute right-0' />
                    </div>
                    <div className='flex items-center relative text-[#A8A8A8]'>
                        <select className='appearance-none bg-transparent outline-none text-[15px] px-2'>
                            <option value="">Tipe Pekerjaan</option>
                            <option value="">Fulltime</option>
                            <option value="">Partime</option>
                            <option value="">Internship</option>
                            <option value="">Contract</option>
                        </select>
                        <IoMdArrowDropdown className='text-xl absolute -right-3' />
                    </div>
                    <button className='text-[#737373] text-[15px]'>Clear all</button>
                </div>
            </div>
            <div className='px-[122px] py-[90px]'>
                <div className='grid grid-cols-4 gap-4'>
                    {Object.values(dataRecruitment).map((data, index) => {
                        const randomIndex = Math.floor(Math.random() * dataColor.length);
                        const randomColor = dataColor[randomIndex];
                        return (
                            <button onClick={() => navigate('/career-detail', {state: {recruitmentId: data.id}})} key={index} className='px-[24px] py-[27px] rounded-[15px] shadow-lg w-full'>
                                <div className='flex flex-col items-start'>
                                <h1 className={`${randomColor.background} ${randomColor.border} ${randomColor.text} border py-[8px] px-[16px] text-[10px] w-fit rounded-md mb-[20px]`}>{data.type}</h1>
                                    <h1 className='text-[#454545] text-[24px] font-medium mb-[13px] truncate text-start w-3/4'>{data.title}</h1>
                                    <h1 className='text-[#A8A8A8] text-[11px] mb-[39px] text-justify line-clamp-3'>{data.jobDescription}</h1>
                                </div>
                                <div className="flex text-[#24A4E0] text-[12px] gap-[8px] items-center">
                                    <MdOutlineWorkOutline />
                                    <h1>{data.position}</h1>
                                </div>
                                <div className="flex text-[#24A4E0] text-[12px] gap-[8px] items-center">
                                    <SlLocationPin />
                                    <h1>{data.placement}</h1>
                                </div>
                            </button>
                        )
                    })}
                </div>
            </div>
            <FooterLandingPage/>
        </div>
    )
}

export default CarierLandingPage