import request from "./utils/request";

class Api {

    static urlAPI() {
        // return "http://localhost:5000/api/v1/"
        return "https://api.humanusia.id/api/v1/"
    }

    static GetRecruitment() {
        let path = 'recruitment/get-all-recruitment';
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
        })
    }
    
    static GetRecruitmentById(id) {
        let path = `recruitment/get-all-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
        })
    }

    static PostAplicant(data) {
        let path = 'entry-aplicant/post';
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}
export default Api;