import React, { useState } from 'react'
import { IconCompanySetting, IconCustomContent, IconDashboard,  IconLogout,  IconUser, LogoH } from '../../assets'
import { RiArrowRightSFill } from 'react-icons/ri';
import { MdMenuOpen } from 'react-icons/md'
import { BsArrowLeftShort } from 'react-icons/bs'
import { Link } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';

const Navbar = ({SubNavbar, NameSubNavbar, LinkBack, NameNavbar}) => {

    const [mobileMenu, setMobileMenu] = useState(false)
    
    const [admin, setAdmin] = useState(false)
    const [job, setJob] = useState(false)
    const [organization, setOrganization] = useState(false)

    const [employee, setEmployee] = useState(false)
    const [configuration, setConfiguration] = useState(false)

    const [timeManagement, setTimeManagement] = useState(false)
    const [attendance, setAttendance] = useState(false)

    return (
        <div>
            <div className={`bg-[#FFFFFF5E] border shadow-sm rounded-[21px] py-[16px] px-[25px] w-full flex items-center justify-between`}>
                <div className={`${ SubNavbar ? 'hidden' : 'block' } space-y-[5px]`}>
                    <h1 className='text-[#282828] text-[16px] font-[500]'>{NameNavbar}</h1>
                    
                </div>
                <div className={`${ SubNavbar ? 'block' : 'hidden' } flex items-center gap-[16px]`}>
                    <Link to={LinkBack}>
                        <BsArrowLeftShort className='text-[#282828] text-2xl'/>
                    </Link>
                    <h1 className='text-[#282828] text-sm font-[500]'>{NameSubNavbar}</h1>
                </div>
                <div className='hidden lg:flex items-center gap-[20px]'>
                    <Link to=''>
                        <div className={'flex items-center gap-[13px] py-[8px] px-[18px] rounded-full'}>
                            <img src={'https://cdn.myanimelist.net/images/characters/15/422168.jpg'} className='object-cover border w-[36px] h-[36px] rounded-full' alt='Humanusia Logo'/>
                            <h1 className='text-[#454545] text-sm font-[500]'>Muh Rizieq Fazlulrahman</h1>
                        </div>
                    </Link>
                </div>
                <button onClick={()=>setMobileMenu(!mobileMenu)} className='flex lg:hidden border w-[41px] h-[41px] rounded-full items-center justify-center'>
                    <MdMenuOpen className='text-2xl text-[#C1121F]'/>
                </button>
            </div>
            <div className={`${mobileMenu ? 'h-[730px] border' : 'h-0 border-0'} mt-2 bg-white rounded-[6px] w-full shadow-sm transition-all duration-1000 ease-in-out overflow-hidden`}>
                <div className='my-[16px] mx-[25px]'>
                    <div className='border-b-2 pt-[20px] pb-[30px]'>
                        <div className='flex items-center justify-center gap-[20px]'>
                            <button className='border w-[41px] h-[41px] rounded-full flex items-center justify-center'>
                                <FaEnvelope className='text-2xl text-[#A8A8A8]'/>
                            </button>
                            <button className='border w-[41px] h-[41px] rounded-full'>
                                <img src={LogoH} className='object-cover' alt='Humanusia Logo'/>
                            </button>
                        </div>
                        <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-6 text-center'>Muhammad Faizal</h1>
                    </div>
                    <div className={`my-[40px] h-[410px] overflow-auto scrollbar-hide flex flex-col`}>
                        <Link to={'/user'} className='flex items-center gap-[16px] mb-[30px]'>
                            <img src={IconUser} alt='Humanusia Icon'/>
                                <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500]'>User</h1>
                        </Link>
                        <Link to={'/dashboard'} className='flex items-center gap-[16px] mb-[30px]'>
                            <img src={IconDashboard} alt='Humanusia Icon'/>
                                <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500]'>Dashboard</h1>
                        </Link>
                        <Link to={'/company-setting'} className='flex items-center gap-[16px] mb-[30px]'>
                            <img src={IconCompanySetting} alt='Humanusia Icon'/>
                                <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500]'>Company Setting</h1>
                        </Link>


                        <button onClick={ () => {setEmployee(!employee); setConfiguration(false); setAdmin(false); setTimeManagement(false)} } className={`flex items-center gap-[16px] text-sm ${employee ? 'text-[#780000]' : 'text-[#A8A8A8]'} w-full justify-between hover:text-[#780000] font-[500]`}>
                            <div className='flex items-center gap-[16px]'>
                                <img src={IconCustomContent} alt='Humanusia Icon'/>
                                    <h1>Custom Content</h1>
                            </div> 
                            <RiArrowRightSFill className='text-xl'/>
                        </button>
                        <div className={`${employee ? 'min-h-[160px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full mb-[30px] pl-[40px] overflow-auto scrollbar-hide`}>        
                            <Link to={'/custom-dashboard'}>
                                <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Dashboard</h1>
                            </Link>
                            <Link to={'/about-us-custom'}>
                                <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>About Us</h1>
                            </Link>
                            <Link to={'/our-brand-custom'}>
                                <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Our Brand</h1>
                            </Link>
                            <Link to={'/carier-custom'}>
                                <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Carrier</h1>
                            </Link>
                            <Link to={'/company-life-custom'}>
                                <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-[20px]'>Company Life</h1>
                            </Link>
                        </div>
                        
                    </div>
                    <div className={`border-t-2 w-full pt-[30px] flex`}>
                        <button className='flex items-center gap-[16px]'>
                            <img src={IconLogout} alt='Humanusia Icon'/>
                                <h1 className='text-sm text-[#780000] font-[500]'>Sign Out</h1>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar