import React, { useEffect, useState } from 'react'
import NavbarLandingPage from '../../../components/NavbarLandingPage'
import FooterLandingPage from '../../../components/FooterLandingPage'
import { MdOutlineWorkOutline } from 'react-icons/md'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../Api'
import moment from 'moment/moment'

const DetailCarier = () => {
    const navigate = useNavigate()
    const params = useLocation()
    const [recruitmentDetail, setRecruitmentDetail] = useState('')
    const [recruitmentCompany, setRecruitmentCompany] = useState('')
    
    const GetRecruitmentDetail = async() => {
        try {
            const response = await Api.GetRecruitmentById(params.state.recruitmentId)
            setRecruitmentDetail(response.data[0])
            setRecruitmentCompany(response.data[0].company)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetRecruitmentDetail()
    }, [])

    return (
        <div>
            <NavbarLandingPage/>
            <div className='bg-[#F6FBFF] px-[120px] py-[75px]'>
                <h1 className='text-[#A8A8A8] text-[14px] font-medium mb-[75px]'>Karir / <span className='text-[#454545]'> Detail Karir</span></h1>
                <div className='rounded-[12px] shadow-md p-[60px]  bg-white'>
                    <div className='flex items-start justify-between'>
                        <h1 className='text-[28px] text-[#2E2E2E] font-semibold'>{recruitmentDetail.title}</h1>
                        <h1 className='text-[#A8A8A8] text-[12px]'>Posted  : {moment(recruitmentDetail.publishDate).format('DD MMMM YYYY')}</h1>
                    </div>
                    <div className='flex items-start justify-between mb-[60px]'>
                        <div>
                            <div className="flex gap-[7px] mb-[7px]">
                                <h1 className='text-[15px] text-[#A8A8A8] font-medium'>{recruitmentCompany.company_name}</h1>
                                <p className='text-[15px] font-semibold text-[#737373]'>{recruitmentDetail.placement}</p>
                            </div>
                            <div className="flex gap-[7px] mb-[13px]">
                                <h1 className='text-[15px] text-[#A8A8A8] font-medium'>Jenis Pekerjaan </h1>
                                <p className='text-[15px] font-semibold text-[#737373]'>{recruitmentDetail.type}</p>
                            </div>
                            <div className="flex text-[#24A4E0] text-[12px] gap-[8px] items-center">
                                <MdOutlineWorkOutline className='text-xl' />
                                <h1>{recruitmentDetail.position}</h1>
                            </div>
                        </div>
                        <button onClick={ () => navigate('/form-lamaran', {state : {recruitmentId : recruitmentDetail.id, position: recruitmentDetail.position}})}>
                            <button className='bg-[#253166] py-[17px] px-[20px] text-white text-[14px] font-medium rounded-md'>Apply Now</button>
                        </button>
                    </div>

                    <div className='mb-[45px]'>
                        <h1 className='text-[18px] font-semibold text-[#454545] mb-[15px]'>Description </h1>
                        <div className='text-[13px] text-[#5C5C5C]'>
                            <p className='mb-2'>{recruitmentDetail.jobDescription}</p>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[18px] font-semibold text-[#454545] mb-[15px]'>Qualification</h1>
                        <div className='text-[13px] text-[#5C5C5C]'>
                            <p className='mb-2'>Requirements:</p>
                            <p className='mb-2'>{recruitmentDetail.qualification}</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterLandingPage/>
        </div>
    )
}

export default DetailCarier