import './App.css';
import { Route, Routes } from 'react-router-dom';
import User from './pages/User';
import Dashboard from './pages/Dashboard';
import CompanySetting from './pages/CompanySetting';
import CustomDashboard from './pages/CustomContent/Dashboard';
import OurBrand from './pages/CustomContent/OurBrand';
import AboutUs from './pages/CustomContent/AboutUs';
import Carier from './pages/CustomContent/Carier';
import SumberLowongan from './pages/CustomContent/Carier/SumberLowongan';
import JenisPekerjaan from './pages/CustomContent/Carier/JenisPekerjaan';
import CompanyLife from './pages/CustomContent/CompanyLife';
import Login from './Auth/Login';
import DashboardLandingPage from './Home/Dashboard';
import CarierLandingPage from './Home/Carier';
import OurBrandLandingPage from './Home/OurBrand';
import DetailCarier from './Home/Carier/DetailCarier';
import FormLamaran from './Home/Carier/FormLamaran';
import ThanksPage from './Home/Carier/ThanksPage';
import WorkLandingPage from './Home/Work';
import Company from './Home/Company';
import {Toaster} from "react-hot-toast"

function App() {
  return (
    <>
      <Toaster
          position="top-center"
          reverseOrder={true}
      />
      <Routes>

        {/* Homepage */}
        <Route name='HomePage' path="/" element={<DashboardLandingPage/>}/>
        <Route name='Work' path="/work" element={<WorkLandingPage/>}/>
        <Route name='Carier' path="/career" element={<CarierLandingPage/>}/>
        <Route name='DetailCarier' path="/career-detail" element={<DetailCarier/>}/>
        <Route name='FormLamaran' path="/form-lamaran" element={<FormLamaran/>}/>
        <Route name='ThanksPage' path="/form-lamaran-success" element={<ThanksPage/>}/>
        <Route name='OurBrand' path="/our-brand" element={<OurBrandLandingPage/>}/>
        <Route name='Company' path="/company" element={<Company/>}/>


        {/* Admin */}
        {/* Login */}
        <Route name='Login' path="/login" element={<Login/>}/>

        <Route name='User' path="/user" element={<User/>}/>
        <Route name='Dashboard' path="/dashboard" element={<Dashboard/>}/>
        <Route name='CompanySetting' path="/company-setting" element={<CompanySetting/>}/>

        {/* Custom Content */}
        <Route name='CustomDashboard' path="/dashboard-custom" element={<CustomDashboard/>}/>
        <Route name='AboutUs' path="/about-us-custom" element={<AboutUs/>}/>
        <Route name='OurBrand' path="/our-brand-custom" element={<OurBrand/>}/>
        <Route name='Carier' path="/carier-custom" element={<Carier/>}/>
        <Route name='SumberLowongan' path="/sumber-lowongan" element={<SumberLowongan/>}/>
        <Route name='JenisPekerjaan' path="/jenis-pekerjaan" element={<JenisPekerjaan/>}/>
        <Route name='CompanyLife' path="/company-life-custom" element={<CompanyLife/>}/>
      </Routes>
    </>
  );
}

export default App;
