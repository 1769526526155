import React from 'react'
import FooterLandingPage from '../../components/FooterLandingPage'
import NavbarLandingPage from '../../components/NavbarLandingPage'
import { Brand1, Brand2, Brand3, Brand4, Brand5, Brand6, Pict5 } from '../../assets'

const OurBrandLandingPage = () => {
  return (
    <div>
        <NavbarLandingPage/>
        <div className="relative">
            <img src={Pict5} alt="" className="w-full" />
            <div className="absolute top-[75px] left-[136px] w-full">
                <h1 className="text-4xl font-bold mb-6 text-blue-900">Our Brands</h1>
                <p className="text-xl text-blue-900">Ethos Kreatif Indonesia memiliki beberapa brand yang membantu menjaga kesehatan anda agar tetap sehat.</p>
            </div>
        </div>

        <div className='px-[74px] py-[84px]'>
            <div className=''>
                <div className="flex gap-[89px] px-[54px] py-[60px] bg-[#669BBC1A]">
                    <img src={Brand1} alt="" />
                    <div>
                        <h1 className='text-[40px] font-bold text-[#253166] mb-[32px]'>Brand 1</h1>
                        <p className='text-[20px] text-[#5C5C5C]'>Freshmag adalah produk herbal dengan komposisi utama madu hutan murni dan kunyit yang telah dipercaya dan dibuktikan khasiatnya oleh ribuan orang untuk mengatasi masalah pada lambung. Madu hutan mengandung senyawa sebagai antioksidan yang mampu menangkal radikal bebas. Sedangkan kunyit mengandung kurkumin yang mampu mengatasi berbagai gangguan pada lambung seperti tukak lambung, infeksi pada lambung dan mengatasi perut kembung.</p>
                    </div>
                </div>
                <div className="flex gap-[89px] px-[54px] py-[60px]">
                    <div>
                        <h1 className='text-[40px] font-bold text-[#253166] mb-[32px]'>Brand 2</h1>
                        <p className='text-[20px] text-[#5C5C5C]'>Generos adalah vitamin herbal yang terbuat dari madu hutan, ekstrak ikan sidat, temulawak, daun pegagan, dan mengkudu yang diolah secara khusus melalui proses fermentasi untuk menutrisi dan membantu mengatasi masalah gangguan perkembangan anak seperti speechdelay (keterlambatan bicara), keterlambatan berjalan, Autis/ ADHD dan gangguan otak lainnya, serta bantu meningkatkan nafsu makan dan meningkatkan kecerdasan otak anak.</p>
                    </div>
                    <img src={Brand2} alt="" />
                </div>
                <div className="flex gap-[89px] px-[54px] py-[60px] bg-[#669BBC1A]">
                    <img src={Brand3} alt="" />
                    <div>
                        <h1 className='text-[40px] font-bold text-[#253166] mb-[32px]'>Brand 3</h1>
                        <p className='text-[20px] text-[#5C5C5C]'>Kopi Rube merupakan minuman kesehatan pria dewasa yang terbuat dari biji kopi pilihan dan diformulasikan dari bahan-bahan herbal kualitas terbaik dan terbukti berkhasiat untuk meningkatkan vitalitas dan stamina pria. Kopi Rube diformulasikan oleh tim ahli dengan herbal menjadikan hasil yang didapat lebih efektif dan memiliki reaksi yang cepat.</p>
                    </div>
                </div>
                <div className="flex gap-[89px] px-[54px] py-[60px]">
                    <div>
                        <h1 className='text-[40px] font-bold text-[#253166] mb-[32px]'>Brand 4</h1>
                        <p className='text-[20px] text-[#5C5C5C]'>Susu Etawaku Platinum merupakan perpaduan antara susu kambing murni dan krimer rendah gula sebagai salah satu alternatif yang dipilih oleh masyarakat untuk menjaga badan tetap bugar dan sehat karena mengandung vitamin dan mineral yang dibutuhkan oleh tubuh. Karena susu kambing etawa sudah diteliti dan terbukti banyak sekali manfaatnya. Selain itu susu kambing etawa memiliki kandungan yang sangat mirip dengan ASI..</p>
                    </div>
                    <img src={Brand4} alt="" />
                </div>
                <div className="flex gap-[89px] px-[54px] py-[60px] bg-[#669BBC1A]">
                    <img src={Brand5} alt="" />
                    <div>
                        <h1 className='text-[40px] font-bold text-[#253166] mb-[32px]'>Brand 5</h1>
                        <p className='text-[20px] text-[#5C5C5C]'>Gizidat merupakan suplemen makanan yang terbuat dari madu hitam yang mengandung ekstrak ikan sidat untuk membantu meningkatkan nafsu makan pada anak karena memiliki kandungan temulawak. Gizidat memiliki rasa yang enak sehingga disukai oleh anak-anak. Dengan kandungan vitamin dan mineral yang lengkap berfungsi memelihara daya tahan tubuh dan meningkatkan kecerdasan anak..</p>
                    </div>
                </div>
                <div className="flex gap-[89px] px-[54px] py-[60px]">
                    <div>
                        <h1 className='text-[40px] font-bold text-[#253166] mb-[32px]'>Brand 6</h1>
                        <p className='text-[20px] text-[#5C5C5C]'>Nutriflakes merupakan sereal yang terbuat dari 5 bahan alami yang diformulasikan mengatasi masalah lambung seperti asam lambung naik, menyembuhkan GERD, menyembuhkan tukak lambung, radang usus halus, radang usus besar, hepatitis, wasir serta mengatasi lambung lainnya secara permanen.</p>
                    </div>
                    <img src={Brand6} alt="" />
                </div>
            </div>
        </div>

        <FooterLandingPage/>
    </div>
  )
}

export default OurBrandLandingPage