import React from 'react'
import NavbarLandingPage from '../../../components/NavbarLandingPage'
import FooterLandingPage from '../../../components/FooterLandingPage'
import { cuate } from '../../../assets'
import { useNavigate } from 'react-router-dom'

const ThanksPage = () => {

    const navigate = useNavigate()

    return (
        <div>
            <NavbarLandingPage/>
            <div className='bg-[#F6FBFF] px-[120px] py-[50px]'>
                <div className='bg-white rounded-[12px] border shadow-lg px-[165px] py-[112px] space-y-[30px] flex items-center justify-between'>
                    <img src={cuate}/>
                    <div className='flex flex-col items-center justify-center space-y-4'>
                        <h1 className='text-[#004F9F] text-[40px] font-bold uppercase'>Thank You</h1>
                        <p className='text-[#A8A8A8] text-[20px] w-1/2 text-center'>We have received your file, For further information we will notify you by e-mail</p>
                        <button onClick={() => navigate('/')} className='px-[50px] py-[15px] rounded-[6px] bg-[#0E5073] text-white text-sm font-medium'>Back To Home</button>
                    </div>
                </div>
            </div>
            <FooterLandingPage/>
        </div>
    )
}

export default ThanksPage