import React from 'react'
import { HiOutlinePencil } from 'react-icons/hi'
import { RiBuildingLine } from 'react-icons/ri'

const CompanyProfile = () => {
  return (
    <div>
        <div className='mb-[29px]'>
            <h1 className='text-[20px] font-medium mb-[6px] text-[#454545]'>Company Profile</h1>
            <p className='text-[#A8A8A8] text-[12px]'>Fill your company Information </p>
        </div>
        
        <div className='space-y-[29px]'>
            <div>
                <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Company Name</h1>
                <input type="text" className='py-[10px] px-[12px] text-[11px] border rounded-md w-[488px]' />
            </div>

            <div className='flex gap-[42px]'>
                <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Company Logo</h1>
                <div className='flex items-center justify-center relative w-[124px] h-[124px] bg-[#D9D9D9] rounded-md'>
                    <button className='w-[29px] h-[29px] bg-[#790000] rounded-full flex items-center justify-center absolute -right-2 -top-2'>
                        <HiOutlinePencil className='text-[white] text-md'/>
                    </button>
                    <RiBuildingLine className='text-7xl text-[#848484]'/>
                </div>
            </div>

            
            <div>
                <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Brief Description</h1>
                <input type="text" className='py-[10px] px-[12px] text-[11px] border rounded-md w-full' />
            </div>

            <div>
                <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Brief Description</h1>
                <textarea rows={3}  className='py-[10px] px-[12px] text-[11px] border rounded-md w-full resize-none' />
            </div>

            <div>
                <h1 className='text-[11px] font-medium text-[#737373] mb-[18.5px]'>Contact</h1>
                <div className='flex gap-[31px] mb-[23px]'>
                    <div>
                        <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Phone</h1>
                        <input type='number' className='py-[10px] px-[12px] text-[11px] border rounded-md w-[313.09px]' />
                    </div>
                    <div>
                        <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Fax</h1>
                        <input type='number' className='py-[10px] px-[12px] text-[11px] border rounded-md w-[313.09px]' />
                    </div>
                    <div>
                        <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Email</h1>
                        <input type='email' className='py-[10px] px-[12px] text-[11px] border rounded-md w-[313.09px]' />
                    </div>
                </div>
                <div className='flex gap-[31px]'>
                    <div>
                        <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Instagram</h1>
                        <input type='text' className='py-[10px] px-[12px] text-[11px] border rounded-md w-[313.09px]' />
                    </div>
                    <div>
                        <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Facebook</h1>
                        <input type='text' className='py-[10px] px-[12px] text-[11px] border rounded-md w-[313.09px]' />
                    </div>
                    <div>
                        <h1 className='text-[11px] font-medium text-[#737373] mb-[5px]'>Youtube</h1>
                        <input type='text' className='py-[10px] px-[12px] text-[11px] border rounded-md w-[313.09px]' />
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default CompanyProfile