import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import Modal from '../../../../components/Modal'
import Sidebar from '../../../../components/Sidebar'
import Navbar from '../../../../components/Navbar'

const SumberLowongan = () => {
  const [showAddLowongan, setShowAddLowongan] = useState()
  const [showEditLowongan, setShowEditLowongan] = useState()
  return (
    <div>
      <Modal 
            activeModal={showAddLowongan}
            title={'Add Content'}
            buttonClose={ () => setShowAddLowongan(!showAddLowongan)}
            width={'551px'}
            content= {
                <div className='space-y-[26px]'>
                    <div className="w-full">
                        <div className='text-[#737373] mb-[26px]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Name<span className='text-[#780000]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[12px] border rounded-md text-[12px] w-full' placeholder='ex: Loker Instagram'/>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className='relative pb-[36px] pt-[11px]'>
                            <div className='flex gap-[12px] absolute right-0'>
                                <button onClick={() => setShowAddLowongan(!showAddLowongan)} className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
      <Modal 
            activeModal={showEditLowongan}
            title={'Edit Lowongan'}
            buttonClose={ () => setShowEditLowongan(!showEditLowongan)}
            width={'551px'}
            content= {
                <div className='space-y-[26px]'>
                    <div className="w-full">
                        <div className='text-[#737373] mb-[26px]'>
                            <h1 className='text-[11px] font-medium mb-[5px]'>Name<span className='text-[#780000]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[12px] border rounded-md text-[12px] w-full' placeholder='ex: Loker Instagram'/>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className='relative pb-[36px] pt-[11px]'>
                            <div className='flex gap-[12px] absolute right-0'>
                                <button onClick={() => setShowEditLowongan(!showEditLowongan)} className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
      <div className='bg-[#EBEFF5] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Sumber Lowongan'} LinkBack={'/carier-custom'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className="flex items-center justify-between">
                            <div className='text-[#0A0A1F]'>
                                <h1 className='text-[16px] font-semibold mb-[5px]'>Sumber Lowongan </h1>
                                <h1 className='text-[10px]'>20 Post</h1>
                            </div>
                            <div>
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                    <div className='relative'>
                                        <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                        <input placeholder='Search Brand...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                    </div>
                                    <button onClick={() => setShowAddLowongan(!showAddLowongan)}  className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus   className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add New</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="relative overflow-x-auto">
                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead class="text-[10px] text-[#737373] uppercase border-b dark:bg-gray-700 dark:text-gray-400 font-semibold">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            ACTION
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-[#454545] text-[12px]'>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <th scope="row" class="px-6 py-[29px] font-medium  whitespace-nowrap dark:text-white">
                                          Sponsor Instagram atau Facebook
                                        </th>
                                        <td class="px-6 py-[29px]">
                                            <div className='w-full flex items-center gap-[12px]'>
                                                <button onClick={() => setShowEditLowongan(!showEditLowongan)}  className='w-[29px] h-[29px] bg-[#24A4E0] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[white] text-lg'/>
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#FF5F5F] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[white] text-lg'/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[12px] text-[#454545]">
                                        <th scope="row" class="px-6 py-[29px] font-medium  whitespace-nowrap dark:text-white">
                                          Sponsor Instagram atau Facebook
                                        </th>
                                        <td class="px-6 py-[29px]">
                                            <div className='w-full flex items-center gap-[12px]'>
                                                <button onClick={() => setShowEditLowongan(!showEditLowongan)}  className='w-[29px] h-[29px] bg-[#24A4E0] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[white] text-lg'/>
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#FF5F5F] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[white] text-lg'/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SumberLowongan