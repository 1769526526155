import React, { useState } from 'react'
import { RxDragHandleDots2 } from "react-icons/rx";
import { CgTrashEmpty } from 'react-icons/cg';
import { AiOutlinePlus } from 'react-icons/ai';
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';

const AboutUs = () => {
    const [editHeadline, setEditHeadline] = useState(true)
    const [editVision, setEditVision] = useState(true)
    const [editMission, setEditMission] = useState(true)
    const [editValue, setEditValue] = useState(true)
    const [itemsMission, setItemsMission] = useState([]);
    const [itemsVision, setItemsVision] = useState([]);

    const addItemMission = () => {
        const newItem = (
          <div className='flex gap-[22px] items-center'>
            <RxDragHandleDots2 className='text-[#A8A8A8] text-lg' />
            <input type="text" className='py-[10px] px-[15px] w-full border rounded-md text-[14px]' />
            <button onClick={() => removeItemMission()}>
                <CgTrashEmpty  className='text-[#780000] text-xl' />
            </button>
          </div>
        );
        setItemsMission(prevItems => [...prevItems, newItem]);
      };

      const removeItemMission = () => {
        setItemsMission(prevItems => {
          const updatedItems = [...prevItems];
          updatedItems.pop();
          return updatedItems;
        });
      };
    const addItemVision = () => {
        const newItem = (
          <div className='flex gap-[22px] items-center'>
            <RxDragHandleDots2 className='text-[#A8A8A8] text-lg' />
            <input type="text" className='py-[10px] px-[15px] w-full border rounded-md text-[14px]' />
            <button onClick={() => removeItemVision()}>
                <CgTrashEmpty  className='text-[#780000] text-xl' />
            </button>
          </div>
        );
        setItemsVision(prevItems => [...prevItems, newItem]);
      };

      const removeItemVision = () => {
        setItemsVision(prevItems => {
          const updatedItems = [...prevItems];
          updatedItems.pop();
          return updatedItems;
        });
      };

  return (
    <div>
        <div className='bg-[#EBEFF5] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={false} NameSubNavbar={'Structure'} LinkBack={''} NameNavbar={'About Us'}/>
                    <div>
                        <h1 className='text-[20px] font-semibold  text-[#2E2E2E]'>Custom Content About Us </h1>
                        <h2 className='text-[12px] text-[#737373]'>Custom content in about us page</h2>
                    </div>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        {editHeadline ? (
                            <>
                                <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Headline 1</h1>
                                <p className='text-[#5C5C5C] text-[14px]'>CONTENT 1 - Let's get acquainted more deeply with Our Company. Companies engaged in Digital Online Marketing open job opportunities for job seekers in the Cilacap, Purwokerto and surrounding areas.</p>
                                <div className='flex gap-[30px]'>
                                    <button onClick={() => setEditHeadline(!editHeadline)} className='text-[12px] font-medium text-[#219EBC]'>Edit</button>
                                    <button className='text-[12px] font-medium text-[#C1121F]'>Delete</button>
                                </div>
                            </>
                        ) : (
                            <>
                            <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Edit Headline 1</h1>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C] font-medium text-[12px]'>Heading 1</h1>
                                <input type="text" className='py-[10px] px-[14px] border rounded-md w-3/4' placeholder='Input heading 1...'/>
                            </div>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C]'>Content 1</h1>
                                <textarea rows={4} className='py-[10px] px-[14px] border rounded-md resize-none w-3/4' placeholder=''/>
                            </div>
                            <div className='relative pb-[36px] pt-[11px]'>
                                <div className='flex gap-[12px] absolute right-0'>
                                    <button onClick={() => setEditHeadline(!editHeadline)}   className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                    <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Save</button>
                                </div>
                            </div>
                            </>
                        )}
                    </div>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        {editVision ? (
                            <>
                                <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Vision</h1>
                                <p className='text-[#5C5C5C] text-[14px]'>Lorem ipsum dolor sit amet consectetur. Sagittis pulvinar interdum aliquam donec vitae donec. Laoreet tincidunt neque venenatis aenean malesuada. Nibh semper adipiscing mollis placerat et tincidunt pulvinar. Nunc mattis nulla scelerisque erat tincidunt enim gravida. Nullam morbi phasellus vel vitae aenean viverra leo. Duis maecenas dui massa quis.</p>
                                <div className='flex gap-[30px]'>
                                    <button onClick={() => setEditVision(!editVision)} className='text-[12px] font-medium text-[#219EBC]'>Edit</button>
                                    <button className='text-[12px] font-medium text-[#C1121F]'>Delete</button>
                                </div>
                            </>
                        ) : (
                            <>
                            <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Edit Vision</h1>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C]'>Vision</h1>
                                <textarea rows={4} className='py-[10px] px-[14px] border rounded-md resize-none w-3/4' placeholder=''/>
                            </div>
                            <div className='relative pb-[36px] pt-[11px]'>
                                <div className='flex gap-[12px] absolute right-0'>
                                    <button onClick={() => setEditVision(!editVision)}   className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                    <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Save</button>
                                </div>
                            </div>
                            </>
                        )}
                    </div>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        {
                            editMission ? (
                                <>
                                    <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Mision</h1>
                                    <div className='text-[#5C5C5C] text-[14px] space-y-[18px]'>
                                        <li>Lorem ipsum dolor sit amet consectetur. Sagittis pulvinar interdum aliquam donec vitae donec. Laoreet tincidunt neque venenatis aenean malesuada. Nibh semper adipiscing mollis placerat et tincidunt pulvinar. Nunc mattis nulla scelerisque erat tincidunt enim gravida. Nullam </li>
                                        <li>Lorem ipsum dolor sit amet consectetur. Sagittis pulvinar interdum aliquam donec vitae donec. Laoreet tincidunt neque venenatis aenean malesuada. Nibh semper adipiscing mollis placerat et tincidunt pulvinar. Nunc mattis nulla scelerisque erat tincidunt enim gravida. Nullam </li>
                                        <li>Lorem ipsum dolor sit amet consectetur. Sagittis pulvinar interdum aliquam donec vitae donec. Laoreet tincidunt neque venenatis aenean malesuada. Nibh semper adipiscing mollis placerat et tincidunt pulvinar. Nunc mattis nulla scelerisque erat tincidunt enim gravida. Nullam </li>
                                    </div>
                                    <div className='flex gap-[30px]'>
                                        <button onClick={() => setEditMission(!editMission)} className='text-[12px] font-medium text-[#219EBC]'>Edit</button>
                                        <button className='text-[12px] font-medium text-[#C1121F]'>Delete</button>
                                    </div>
                                </>
                            ) :
                            (
                                <>
                                    <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Edit Mission</h1>
                                    <div className='flex gap-[22px] items-center'>
                                        <RxDragHandleDots2 className='text-[#A8A8A8] text-lg' />
                                        <input type="text" className='py-[10px] px-[15px] w-full border rounded-md text-[14px]' />
                                        <button onClick={() => removeItemMission()}>
                                            <CgTrashEmpty  className='text-[#780000] text-xl' />
                                        </button>
                                    </div>
                                    {itemsMission.map((item, index) => (
                                        <div key={index}>{item}</div>
                                    ))}
                                    <button className='bg-[#003049] py-2 px-[11px] rounded-[10px] flex items-center gap-[16px] mt-[31px]' onClick={addItemMission}>
                                        <AiOutlinePlus className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                    </button>
                                    <div className='relative pb-[36px] pt-[11px]'>
                                        <div className='flex gap-[12px] absolute right-0'>
                                            <button onClick={() => setEditMission(!editMission)}   className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                            <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Save</button>
                                        </div>
                                    </div>
                                    

                                    
                                </>
                            )
                        }
                        
                    </div>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        {editValue ? (
                            <>
                                <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Our Value </h1>
                                <h2 className='text-[#5C5C5C] text-[14px]'>CONTENT 2 - Lorem ipsum dolor sit amet consectetur. Odio dignissim varius pellentesque id.</h2>
                                <div className='text-[#5C5C5C] text-[14px] space-y-[18px]'>
                                    <li>Lorem ipsum dolor sit amet consectetur. Sagittis pulvinar interdum aliquam donec vitae donec. Laoreet tincidunt neque venenatis aenean malesuada. Nibh semper adipiscing mollis placerat et tincidunt pulvinar. Nunc mattis nulla scelerisque erat tincidunt enim gravida. Nullam </li>
                                    <li>Lorem ipsum dolor sit amet consectetur. Sagittis pulvinar interdum aliquam donec vitae donec. Laoreet tincidunt neque venenatis aenean malesuada. Nibh semper adipiscing mollis placerat et tincidunt pulvinar. Nunc mattis nulla scelerisque erat tincidunt enim gravida. Nullam </li>
                                    <li>Lorem ipsum dolor sit amet consectetur. Sagittis pulvinar interdum aliquam donec vitae donec. Laoreet tincidunt neque venenatis aenean malesuada. Nibh semper adipiscing mollis placerat et tincidunt pulvinar. Nunc mattis nulla scelerisque erat tincidunt enim gravida. Nullam </li>
                                </div>
                                <div className='flex gap-[30px]'>
                                    <button onClick={() => setEditValue(!editValue)} className='text-[12px] font-medium text-[#219EBC]'>Edit</button>
                                    <button className='text-[12px] font-medium text-[#C1121F]'>Delete</button>
                                </div>
                            </>
                        ) : (
                            <>
                            <h1 className='text-[#0A0A1F] text-[20px] font-semibold'>Our Value</h1>
                            <div className='text-[12px] flex items-center gap-[43px]'>
                                <h1 className='text-[#5C5C5C]'>Value Description</h1>
                                <textarea rows={4} className='py-[10px] px-[14px] border rounded-md resize-none w-3/4' placeholder=''/>
                            </div>
                                    <div className='flex gap-[22px] items-center'>
                                        <RxDragHandleDots2 className='text-[#A8A8A8] text-lg' />
                                        <input type="text" className='py-[10px] px-[15px] w-full border rounded-md text-[14px]' />
                                        <button onClick={() => removeItemVision()}>
                                            <CgTrashEmpty  className='text-[#780000] text-xl' />
                                        </button>
                                    </div>
                                    {itemsVision.map((item, index) => (
                                        <div key={index}>{item}</div>
                                    ))}
                                    <button className='bg-[#003049] py-2 px-[11px] rounded-[10px] flex items-center gap-[16px] mt-[31px]' onClick={addItemVision}>
                                        <AiOutlinePlus className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                    </button>
                                    <div className='relative pb-[36px] pt-[11px]'>
                                        <div className='flex gap-[12px] absolute right-0'>
                                            <button onClick={() => setEditValue(!editValue)}   className='rounded-md text-[14px] font-medium py-[10px] px-[25px] bg-[#ECECEC] text-[#003049]'>Cancel</button>
                                            <button className='rounded-md text-[14px] font-medium py-[10px] px-[28.5px] bg-[#0E5073] text-white'>Save</button>
                                        </div>
                                    </div>
                            </>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs