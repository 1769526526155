import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

function ColorSetting() {
  const [displayColorPickerPrimary, setDisplayColorPickerPrimary] = useState(false);
  const [displayColorPickerSecondary, setDisplayColorPickerSecondary] = useState(false);
  const [colorPrimary, setColorPrimary] = useState({
    r: '120',
    g: '0',
    b: '0',
    a: '1',
  });
  const [colorSecondary, setColorSecondary] = useState({
    r: '0',
    g: '48',
    b: '73',
    a: '1',
  });

  const handleClickPrimary = () => {
    setDisplayColorPickerPrimary(!displayColorPickerPrimary);
  };
  const handleClickSecondary = () => {
    setDisplayColorPickerSecondary(!displayColorPickerSecondary);
  };

  const handleClosePrimary = () => {
    setDisplayColorPickerPrimary(false);
  };
  const handleCloseSecondary = () => {
    setDisplayColorPickerSecondary(false);
  };

  const handleChangePrimary = (newColor) => {
    setColorPrimary(newColor.rgb);
  };
  const handleChangeSecondary = (newColor) => {
    setColorSecondary(newColor.rgb);
  };

  const styles1 = reactCSS({
    'default': {
      colorPrimary: {
        width: '427px',
        height: '29px',
        borderRadius: '2px',
        background: `rgba(${colorPrimary.r}, ${colorPrimary.g}, ${colorPrimary.b}, ${colorPrimary.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  const styles2 = reactCSS({
    'default': {
      colorSecondary: {
        width: '427px',
        height: '29px',
        borderRadius: '2px',
        background: `rgba(${colorSecondary.r}, ${colorSecondary.g}, ${colorSecondary.b}, ${colorSecondary.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div>

      <div className='mb-[29px]'>
        <h1 className='text-[#454545] text-[20px] font-medium mb-[6px]'>Color Setting</h1>
        <h2 className='text-[12px] text-[#A8A8A8]'>Custom your color</h2>
      </div>


      <div className="flex items-center gap-[43px]">
        <h1 className='text-[11px] font-medium text-[#737373]'>Primary Color</h1>
        <div>
          <div style={styles1.swatch} onClick={handleClickPrimary}>
            <div style={styles1.colorPrimary} />
          </div>
          {displayColorPickerPrimary ? (
            <div style={styles1.popover}>
              <div style={styles1.cover} onClick={handleClosePrimary} />
              <SketchPicker color={colorPrimary} onChange={handleChangePrimary} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center gap-[26px]">
        <h1 className='text-[11px] font-medium text-[#737373]'>Secondary Color</h1>
        <div>
          <div style={styles2.swatch} onClick={handleClickSecondary}>
            <div style={styles2.colorSecondary} />
          </div>
          {displayColorPickerSecondary ? (
            <div style={styles2.popover}>
              <div style={styles2.cover} onClick={handleCloseSecondary} />
              <SketchPicker color={colorSecondary} onChange={handleChangeSecondary} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ColorSetting;