import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import Sidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'

const Carier = () => {
  return (
    <div>
        <div className='bg-[#EBEFF5] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={false} NameSubNavbar={'Structure'} LinkBack={''} NameNavbar={'Carier'}/>
                    <div>
                        <h1 className='text-[#2E2E2E] text-[16px] font-semibold'>Carier Setting</h1>
                        <h2 className='text-[#0A0A1F] text-[10px]'>2 Option </h2>
                    </div>
                    <div >
                        <Link to='/sumber-lowongan'>
                            <button className='bg-white px-[34px] py-[19px] rounded-[10px] border flex items-center justify-between w-full mb-[16px]'>
                                <h1 className='text-[14px] font-medium text-[#454545]'>Sumber Lowongan </h1>
                                <IoIosArrowForward className='text-xl' />
                            </button>
                        </Link>
                        <Link to={'/jenis-pekerjaan'}>
                            <button className='bg-white px-[34px] py-[19px] rounded-[10px] border flex items-center justify-between w-full'>
                                <h1 className='text-[14px] font-medium text-[#454545]'>Jenis Pekerjaan </h1>
                                <IoIosArrowForward className='text-xl' />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Carier