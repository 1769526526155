import React, { useState } from 'react'
import { Logo } from '../../assets'
import { BiSearch } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const NavbarLandingPage = () => {
    const [index, setIndex] = useState()
    
  return (
    <div className='flex items-center border-b-2'>
        <div className='py-[18px] px-[120px] w-full'>
            <div className='flex items-center justify-between w-full'>
                <div>
                    <img src={Logo} alt="" className='w-[143px] h-[44px]'/>
                </div>
                <div className='flex gap-[34px] text-[#A8A8A8] text-[14px] font-medium items-center'>
                    <Link to='/'>
                        <button onClick={() => setIndex(0)} className={index === 0? 'text-[#780000] border-b-2 border-[#780000]' : ''}>Dashboard</button>
                    </Link>
                    <Link  to='/work'>
                        <button onClick={() => setIndex(1)} className={index === 1? 'text-[#780000] border-b-2 border-[#780000]' : ''}>Work</button>
                    </Link>
                    <Link to={'/company'}>
                        <button onClick={() => setIndex(2)} className={index === 2 ? 'text-[#780000] border-b-2 border-[#780000]' : ''}>Company</button>
                    </Link>
                    {/* <Link to={'/our-brand'}>
                        <button onClick={() => setIndex(3)} className={index === 3? 'text-[#780000] border-b-2 border-[#780000]' : ''}>Our Brand</button>
                    </Link> */}
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default NavbarLandingPage