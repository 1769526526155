import React, { useState } from 'react'

const FormInput = ({title, placeholder, onChange, type, fieldType, option, htmlFor, checkFile}) => {

    return (
        <div className='w-full'>
            {fieldType === 'dropdown' ?
            <div className='space-y-2 w-full'>
                <h1 className='text-[#737373] text-[11px]'>{title} <span className='text-[11px] text-[#C1121F]'>*</span></h1>
                <select type={!type ? 'text' : type} onChange={onChange} className='border border-[#CACACA] rounded-[6px] px-3 py-2 text-[12px] text-[#A8A8A8] outline-none w-full' placeholder={placeholder}>
                    {option.map((item) => {
                        return (
                            <option value={item.value}>{item.label}</option>
                        )
                    })}
                </select>
            </div>
            : fieldType === 'textarea' ?
            <div className='space-y-2 w-full'>
                <h1 className='text-[#737373] text-[11px] font-medium'>{title} <span className='text-[11px] text-[#C1121F]'> *</span></h1>
                <textarea rows={7} onChange={onChange} className='border border-[#CACACA] rounded-[6px] px-3 py-2 text-[12px] text-[#A8A8A8] outline-none w-full' placeholder={placeholder}/>
            </div>
            : fieldType === 'file' ?
            <div className={`${checkFile ? 'bg-white' : 'bg-[#F7F7F7]'} rounded-[12px] shadow-lg px-[42px] py-[32px] border-dashed border-2 border-[#226598]`}>
                <label htmlFor={htmlFor} className='space-y-[30px]'>
                    <h1 className='text-[#454545] font-semibold'>{title} <span className='text-[11px] text-[#C1121F]'>*</span></h1>
                    <div className='w-full flex flex-col items-center justify-center gap-4'>
                        <svg width="29" height="37" viewBox="0 0 29 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.2174 9.55928C28.4576 9.79951 28.5938 10.1238 28.5938 10.4642V35.1562C28.5938 35.8649 28.0212 36.4375 27.3125 36.4375H1.6875C0.978809 36.4375 0.40625 35.8649 0.40625 35.1562V1.84375C0.40625 1.13506 0.978809 0.5625 1.6875 0.5625H18.6921C19.0324 0.5625 19.3607 0.698633 19.601 0.938867L28.2174 9.55928ZM25.6389 11.0527L18.1035 3.51738V11.0527H25.6389ZM6.8125 17.2988C6.72755 17.2988 6.64607 17.3326 6.586 17.3926C6.52593 17.4527 6.49219 17.5342 6.49219 17.6191V19.541C6.49219 19.626 6.52593 19.7074 6.586 19.7675C6.64607 19.8276 6.72755 19.8613 6.8125 19.8613H22.1875C22.2725 19.8613 22.3539 19.8276 22.414 19.7675C22.4741 19.7074 22.5078 19.626 22.5078 19.541V17.6191C22.5078 17.5342 22.4741 17.4527 22.414 17.3926C22.3539 17.3326 22.2725 17.2988 22.1875 17.2988H6.8125ZM6.8125 22.7441C6.72755 22.7441 6.64607 22.7779 6.586 22.838C6.52593 22.898 6.49219 22.9795 6.49219 23.0645V24.9863C6.49219 25.0713 6.52593 25.1528 6.586 25.2128C6.64607 25.2729 6.72755 25.3066 6.8125 25.3066H14.1797C14.2646 25.3066 14.3461 25.2729 14.4062 25.2128C14.4663 25.1528 14.5 25.0713 14.5 24.9863V23.0645C14.5 22.9795 14.4663 22.898 14.4062 22.838C14.3461 22.7779 14.2646 22.7441 14.1797 22.7441H6.8125Z" fill="#253166"/>
                        </svg>
                        <h1 className='text-[#226598] font-semibold'>{!checkFile ? 'Drop your file or Browse' : checkFile}</h1>
                        <h1 className='text-[#CACACA]'>Acceptable file types is PDF. Max file size is 2 MB.</h1>
                    </div>
                    <input id={htmlFor} accept=".pdf" onChange={onChange} type='file' className='hidden'/>
                </label>
            </div>
            :
            <div className='space-y-2 w-full'>
                <h1 className='text-[#737373] text-[11px] font-medium'>{title} <span className='text-[11px] text-[#C1121F]'> *</span></h1>
                <input type={!type ? 'text' : type} onChange={onChange} className='border border-[#CACACA] rounded-[6px] px-3 py-2 text-[12px] text-[#A8A8A8] outline-none w-full' placeholder={placeholder}/>
            </div>
            }
        </div>
    )
}

export default FormInput