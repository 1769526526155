import React from 'react'
import { Logo } from '../../assets'
import { BsInstagram } from 'react-icons/bs'

const FooterLandingPage = () => {
  return (
    <div className='border-t-2'>
        <div className='p-[77px]'>
            <div className="flex justify-between">
                <div>
                    <img src={Logo} alt="" className='w-[208px] h-[64px] mb-[33px]'/>
                    <div className="flex gap-[25px] text-[16px] text-[#5C5C5C] items-center mb-[18px]">
                        <BsInstagram className='text-[#669BBC]' />
                        <h1>@Humanusia.id</h1>
                    </div>
                    <div className="flex gap-[25px] text-[16px] text-[#5C5C5C] items-center">
                        <BsInstagram className='text-[#669BBC]' />
                        <h1>@Powerkerto</h1>
                    </div>
                </div>
                <div className='text-[16px]'>
                    <h1 className='font-bold mb-[19px]'>Profile</h1>
                    <div className='space-y-[15px]'>
                        <h1>About Us</h1>
                        <h1>Our Brands</h1>
                        <h1>Carrier</h1>
                        <h1>Company Life</h1>
                    </div>
                </div>
                <div className='text-[16px]'>
                    <h1 className='font-bold mb-[19px]'>Services</h1>
                    <div className='space-y-[15px]'>
                        <h1>Contact Us</h1>
                        <h1>Help</h1>
                        <h1>FAQ</h1>
                    </div>
                </div>
                <div className='text-[16px]'>
                    <h1 className='font-bold mb-[19px]'>Company Address</h1>
                    <h1 className='mb-[34px]'>Ethos Work Space Jl. Kinibalu No. 44 Kabupaten Cilacap Jawa Tengah</h1>
                    <h1 className='font-bold mb-[19px]'>Branch Address</h1>
                    <h1>Jl. Gunung Slamet Purwokerto Utara Banyumas, Jawa Tengah</h1>
                </div>
            </div>
        </div>
        <div className='bg-[#780000] w-full'>
            <h1 className='text-[16px] text-white text-center py-[32px]'>© 2022 Humanusia</h1>
        </div>
    </div>
  )
}

export default FooterLandingPage